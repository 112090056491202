import React, { Component } from 'react';

import LoadingScreen from './loadingScreen';
import SectionCard from '../components/sectionCard';

import Model from '../model';

import '../css/group.css';

function _s(arr){
	return arr.length === 1 ? '' : 's';
}
export default class GroupScreen extends Component {
	constructor(){
		super();
		this.state = {
			'loading': true,
		};
	}

	componentDidMount(){
		this.groupID = this.props.match.params.groupID;

		let promises = [
			Model.getGroupInfo(this.groupID),
			Model.getUsers(this.groupID),
			Model.getInvites(this.groupID),
			Model.getEvents(this.groupID),
		]

		Promise.all(promises).then(([groupInfo, users, invites, events])=> {
			this.setState({
				...groupInfo,
				users: users,
				invites: invites,
				events: events,
				loading:false,
			});
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		return (
			<div className="page group-screen">
				<h2>{this.state.name}</h2>

				<SectionCard title={`${this.state.events.length} Event${_s(this.state.events)}`}>
					<ul>
						{
							this.state.events.map((event) =>
								<li key={event.id}>
									<a href={`/groups/${this.groupID}/events/${event.id}/admin`}>{event.name}</a>
								</li>
							)
						}
					</ul>
				</SectionCard>

				<SectionCard title={`${this.state.users.length} Admin${_s(this.state.users)}`}>
					<ul>
						{
							this.state.users.map((user) =>
								<li key={user.id}>
									{user.displayName !== '' ? user.displayName : 'User\'s display name not set'}
								</li>
							)
						}
					</ul>
				</SectionCard>
				<SectionCard title={`${this.state.invites.length} Invitation${_s(this.state.invites)} Queued`}>
					<ul>
						{
							this.state.invites.map((invite) =>
								<li key={invite.id}>
									<a href={`mailto:${invite.id}`}>{invite.id}</a>
								</li>
							)
						}
					</ul>
					<hr />
					<NewUserForm onError={this.props.onError} onInviteSent={(invite)=>this.onInviteSent(invite)} groupID={this.groupID}/>
				</SectionCard>

			</div>
		)
	}

	onInviteSent(invite){
		let invites = this.state.invites;
		invites.push(invite);
		this.setState({'invites':invites});
	}
}

class NewUserForm extends Component {
	constructor(){
		super();
		this.state = {
			email: '',
			disabled: false,
			valid: false,
		}
	}

	render(){
		return <form onSubmit={(e)=>{ e.preventDefault(); return false; }}>
			<div className={'responsive-form tight' + (this.state.disabled?' disabled':'')}>

				<label>Email address</label>
				<div><input type="email" value={this.state.email} onChange={(event) => this.updateEmail(event.target.value)}/></div>

				<label />
				<div><button type="submit" onClick={()=>this.sendInvite()} className="btn btn-primary" disabled={!this.state.valid}>Send invite</button></div>
			</div>
		</form>
	}

	updateEmail(email){
		let pattern = /\S+@\S+\.\S+/;
		this.setState({
			email: email,
			valid: pattern.test(email.toLowerCase()),
		})
	}

	sendInvite(){
		this.setState({'disabled':true});

		Model.sendAdminInvite(this.props.groupID, this.state.email)
			.then(()=>{
				this.props.onInviteSent({id: this.state.email});
				this.setState({'disabled': false, email:''});
			})
			.catch((err)=>{
				this.setState({'disabled': false});
				this.props.onError(err, 'Failed to send invitation');
			});
	}
}