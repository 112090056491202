import React, { Component } from 'react';

export default class AssignmentTable extends Component {
	constructor(props) {
		super(props);
		this.state = { ...props };
	}

	render(){
		let judgeCounts = new Map();
		let participantCounts = new Map();

		for(let judgeID in this.state.assignments){
			let judgeAssignments = this.state.assignments[judgeID];
			for(let participantID in judgeAssignments){
				let assigned = judgeAssignments[participantID] === true ? 1 : 0;

				judgeCounts.set(judgeID, (judgeCounts.get(judgeID)||0) + assigned);
				participantCounts.set(participantID, (participantCounts.get(participantID)||0) + assigned);
			}
		}

		let filteredParticipants = {};
		for(let participantID in this.props.participants){
			let participant = this.props.participants[participantID];
			let visible = (this.props.categoryFilter === '') || (this.props.categoryFilter === participant.categoryID);
			if(visible && this.props.categories[participant.categoryID]){
				filteredParticipants[participantID] = participant;
			}
		}

		return <table className={'table table-bordered table-striped table-hover' + (this.props.compactView ? ' compact' : '')}>
			<thead>
				<tr>
					<td>&nbsp;</td>
					<th colSpan={Object.keys(this.state.judges).length+1} className="table-group-header">Judges</th>
				</tr>
				{
					this.props.compactView ? null : (
						<tr>
							<th className="table-group-header" style={{textAlign:'left'}}>Participants</th>
							{
								Object.keys(this.state.judges).map((judgeID) =>
									{
										let judge = this.state.judges[judgeID];

										return <th key={'th-'+judgeID} title={judge.details}>
											{this.props.compactView ? '': ((judge.uid ? '✓ ':'') + judge.name)}
										</th>
									}
								)
							}
							<td>&nbsp;</td>
						</tr>
					)
				}
			</thead>
			<tbody>
				{
					Object.keys(filteredParticipants).map((participantID, i)=>{
						let participant = filteredParticipants[participantID];

						return <tr key={participantID}>
							{
								(i === 0 && this.props.compactView) ? (
									<th
										rowSpan={Object.keys(filteredParticipants).length}
										style={{writingMode:'vertical-rl',verticalAlign:'top'}}
									>Participants</th>
								) : null
							}
							{this.props.compactView ? null: <th title={participant.details}>{participant.name}</th>}
							{
								Object.keys(this.state.judges).map((judgeID)=>{
									let judge = this.state.judges[judgeID];

									if(this.state.editMode){
										return <td key={judgeID} className="text-center" title={participant.name + ' / ' + ((judge.uid ? '✓ ':'') + judge.name)}>
											<input
												type="checkbox"
												checked={this.props.assignments[judgeID][participantID]}
												onChange={()=>this.toggleAssignment(judge, participant)}
											/>
										</td>
									}else{
										return <td key={judgeID} className="text-center">
											{(this.props.assignments[judgeID][participantID]) ? '✔' : ''}
										</td>;
									}
								})
							}
							<th className="summary">{participantCounts.get(participantID) || 0}</th>
						</tr>
					})
				}
			</tbody>
			<tfoot>
				<tr>
					<th className="summary">Totals</th>
					{
						Object.keys(this.state.judges).map((judgeID) =>
							{
								return <th key={judgeID} className="summary">
									{judgeCounts.get(judgeID) || 0}
								</th>
							}
						)
					}
					<th className="summary">&nbsp;</th>
				</tr>
			</tfoot>
		</table>
	}

	toggleAssignment(judge, participant){
		if(this.props.onAssignmentChanged){
			this.props.onAssignmentChanged(judge, participant);
		}else{
			let assignments = this.state.assignments;
			assignments[judge.id][participant.id] = !assignments[judge.id][participant.id];
			this.setState({assignments: assignments});
		}
	}
}