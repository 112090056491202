import React, { Component } from 'react';
import LoadingScreen from './loadingScreen';
import SectionCard from '../components/sectionCard';
import BigOlButton from '../components/bigOlButton';

import Model from '../model';

export default class ProfileScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: true,
			groups: [],
		};
	}

	componentDidMount(){
		let promises = [Model.getMyInfo(), Model.getGroups()];

		Promise.all(promises).then(([info, groups]) => {
			if(info.judgeData && info.judgeData.eventID && info.judgeData.groupID && info.judgeData.judgeID){
				this.props.history.push('/judging/' + info.judgeData.groupID + '/' + info.judgeData.eventID + '/' + info.judgeData.judgeID);
			}else{
				this.setState({
					...info,
					groups: groups,
					loading: false,
				});
			}
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		let saveButton = null;
		if(this.state.saving){
			saveButton = <button className="btn btn-primary" disabled={true}>Saving...</button>;
		}else{
			saveButton = <button className="btn btn-primary" onClick={()=>this.save()}>Save</button>;
		}

		return (
			<div className="container page">
				<SectionCard title="My information">
					<div className="responsive-form">
						<label className="control-label col-form-label col-sm-2">Display name</label>
						<input type="text" className="form-control" defaultValue={this.state.displayName} onChange={(e)=>this.setState({displayName: e.target.value})} />

					</div>
					{saveButton}
				</SectionCard>

				{
					this.state.groups.length === 0 ? (
						<div>
							{
								this.state.superAdmin ? (
									<h3>No groups yet</h3>
								):(
									<div>
										<p>You don't belong to any groups yet :(</p>
										<p>Please ask your team leader to grant you access. They'll need to know your email address.</p>
									</div>
								)
							}
						</div>
					):(
						this.state.groups.map((group) =>
							<GroupCard key={group.id} group={group} canAddEvents={this.state.superAdmin} onError={this.props.onError} onCreateEvent={(event)=>this.createEvent(group.id, event)} />
						)
					)
				}

				{
					this.state.superAdmin ? (
						<SectionCard title="Add new group">
							<NewEntityForm onError={this.props.onError} label="Group" onCreate={(group)=>this.createGroup(group)}/>
						</SectionCard>
					):null
				}
			</div>
		);
	}

	createGroup(group){
		return Model.createGroup(group.id, group.name)
			.then(() => {
				this.setState((state) => {
					let groups = [...state.groups];
					groups.push(group);
					return {groups: groups};
				});
			})
	}

	save(){
		this.setState({saving:true});
		Model.saveMe(this.state).then(() => {
			this.setState({saving:false});
		})
	}
}

class GroupCard extends Component {
	constructor(props){
		super(props);
		this.state = {
			loading: true,

			group: props.group,
			events: [],
		};
	}

	componentDidMount(){
		Model.getEvents(this.state.group.id).then( (events) => {
			this.setState({
				events: events,
				loading: false,
			});
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		return <SectionCard title={<a href={`/groups/${this.state.group.id}/admin`}>{this.state.group.name}</a>}>
			<h4>{this.state.events.length} Event{this.state.events===1?'':'s'}</h4>
			<ul>
				{
					this.state.events.map((event) =>
						<li key={event.id}>
							<a href={`/groups/${this.state.group.id}/events/${event.id}/admin`}>{event.name}</a>
						</li>
					)
				}
			</ul>

			{
				this.props.canAddEvents ? (
					<details>
						<summary>Add new event</summary>
						<NewEntityForm label="Event" onCreate={(event)=>this.createEvent(event)} onError={this.props.onError}/>
				</details>
				):null
			}

			{
				this.props.canAddEvents ? (
					<details>
						<summary>Copy event</summary>

						From: <select onChange={(e) => this.setState({copySrcID: e.target.value})}>
								<option />
								{this.state.events.map((event) => <option key={event.id} value={event.id}>{event.name}</option>)}
							</select>
						To: <input type="text" placeholder="new-event-id" onChange={(e) => this.setState({copyDestID: e.target.value})} />

						{
							this.state.copying ? '⏳ Copying...' : (
								<button disabled={this.state.copying} onClick={()=>this.copyEvent()} className="btn btn-primary">Copy</button>
							)
						}
					</details>
				):null
			}
		</SectionCard>
	}

	createEvent(event){
		return Model.createEvent(this.props.group.id, event.id, event.name)
			.then(()=>{
				this.setState((state)=>{
					let events = [...this.state.events];
					events.push(event);
					return {
						events: events,
						disabled: false,
					}
				});
			})
	}

	copyEvent(){
		this.setState({copying: true});
		Model.copyEvent(
			this.props.group.id, this.state.copySrcID,
			this.props.group.id, this.state.copyDestID
		).then(() => {
			window.location.reload();
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}
}

class NewEntityForm extends Component {
	constructor(){
		super();
		this.state = {
			name: '',
			id: '',
			idModified: false,
			disabled: false,
		}
	}

	render(){
		return <div className={'responsive-form' + (this.state.disabled?' disabled':'')}>
			<label>{this.props.label} name</label>
			<div><input type="text" value={this.state.name} onChange={(event) => this.onNameUpdated(event.target.value)}/></div>

			<label>{this.props.label} ID</label>
			<div><input type="text" value={this.state.id} onChange={(event) => this.onIDUpdated(event.target.value)}/></div>

			<label />
			<div><button onClick={()=>this.onCreate()} className="btn btn-primary" disabled={this.state.name === '' || this.state.id === ''}>Create {this.props.label}</button></div>
		</div>
	}

	onCreate(){
		this.setState({'disabled':true});
		this.props.onCreate(this.state)
			.then(()=>{
				this.setState({'id':'', 'name':'', 'idModified':false, 'disabled':false})
			})
			.catch((err)=>{
				this.props.onError(err, `Failed to create ${this.props.label}`);
				this.setState({'disabled': false});
			});
	}

	nameToID(name){
		return name.trim().toLowerCase().replace(/ /g, '-');
	}

	onNameUpdated(name){
		let newState = {name: name};
		if(!this.state.idModified){
			newState = {...newState, id:this.nameToID(name)};
		}
		this.setState(newState);
	}

	onIDUpdated(id){
		this.setState({
			id: id,
			idModified:true && (id !== '')
		})
	}
}
