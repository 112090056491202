import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

import LoadingScreen from './loadingScreen';
import SectionCard from '../components/sectionCard';
import ProgressBar from '../components/progressBar';

import Model from '../model';

import '../css/judging.css';

export default class JudgingScreen extends Component {
	constructor(){
		super();
		this.state = {
			'loading': true,
		};
	}

	componentDidMount(){
		this.groupID = this.props.match.params.groupID;
		this.eventID = this.props.match.params.eventID;
		this.judgeID = this.props.match.params.judgeID;

		let promises = [];

		promises.push(Model.getJudgingAssignments(this.groupID, this.eventID, this.judgeID));
		promises.push(Model.getJudgeInfo(this.groupID, this.eventID, this.judgeID));
		promises.push(Model.getCategories(this.groupID, this.eventID));

		Promise.all(promises).then(values => {
			let [assignments, judge, categories] = values;

			this.setState({
				assignments: assignments,
				judge: judge,
				categories: categories,
				loading:false,
			});
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		let assignmentCompletionStates = {};
		let participantIDs = Object.keys(this.state.assignments);
		let completeCount = 0;

		let assignedCategories = {};

		for(let participantID of participantIDs){
			let participant = this.state.assignments[participantID];

			let scoresComplete = true;

			let rubric = this.state.categories[participant.categoryID].rubric;
			for(let rubricItem of rubric){
				if(!this.state.assignments[participantID].scores || !this.state.assignments[participantID].scores[rubricItem.name]){
					scoresComplete = false;
					break;
				}
			}
			if(scoresComplete){
				completeCount++;
			}
			assignmentCompletionStates[participantID] = scoresComplete;

			if(!(participant.categoryID in assignedCategories)){
				assignedCategories[participant.categoryID] = [];
			}
			assignedCategories[participant.categoryID].push(participantID);
		}

		let allDoneMessage = completeCount === participantIDs.length ? <div style={{textAlign: 'center'}}><h3>You're all done!</h3>(but you can still make edits until judging is closed)</div> : null;

		return (
			<div className="page judging-screen">
				<h2>{this.state.judge.name}</h2>
				{
					participantIDs.length === 0 ? (
						<h3 style={{textAlign: 'center'}}>Your judging participantIDs are no longer present. The participants you were assigned have probably been disqualified and/or removed by an event administrator. Sorry for the inconvenience.</h3>
					):(
						<React.Fragment>
							<ProgressBar progress={completeCount/participantIDs.length}>
								{completeCount} of {participantIDs.length} = {(completeCount/participantIDs.length*100).toFixed(0)}%
							</ProgressBar>

							{ allDoneMessage }
							{
								Object.keys(assignedCategories).map((categoryID) => {
									return <div>
										{ Object.keys(assignedCategories).length > 1 ? <h3>{this.state.categories[categoryID].name}</h3> : null }
										{
											assignedCategories[categoryID].map((participantID)=>{
												let participant = this.state.assignments[participantID];
												let scoresComplete = assignmentCompletionStates[participantID];
												let title = (participant.projectTitle && participant.projectTitle !== '') ? participant.projectTitle : participant.name;
												let details = participant.details;
												let maxLength = 255;
												if (details && details.length > maxLength){
													details = details.substring(0, maxLength-1) + '…';
												}

												return <SectionCard key={participantID} title={title}>
													<button className={'score-button btn btn-' + (scoresComplete ? 'secondary' : 'primary')} onClick={()=>this.gotoRubric(participantID)}>
														{scoresComplete ? `${participant.rawScore}/${participant.maxPossible}` : '✏'}
													</button>
													<h4>{participant.name}</h4>
													<ReactMarkdown>{details}</ReactMarkdown>
													<p><em>Judging status: {scoresComplete ? 'Complete' : 'Incomplete'}</em></p>
												</SectionCard>
											})
										}
									</div>
								})
							}
							{ allDoneMessage }
						</React.Fragment>
					)
				}
			</div>
		);
	}

	gotoRubric(participantID){
		this.props.history.push('/judging/' + this.groupID + '/' + this.eventID + '/' + this.judgeID + '/' + participantID);
	}
}
