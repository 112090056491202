import React, { Component } from 'react';

export default class RadioGroup extends Component {
	constructor(props) {
		super(props);
	
		this.state = {
			value: props.value,
		};
	}

	render() {
		return (
			<div className={'radioGroup ' + this.props.className}>
				{
					this.props.choices.map((option) => {
						return (
							<div
								key={option}
								onClick={()=>this.toggleChoice(option)}
								className={'radioGroupChoice radioGroupChoice-' + option + ' ' + (this.state.value === option ? ' selected' : '' )}
							>
								{option}
							</div>
						);
					})
				}
			</div>
		);
	}
	
	toggleChoice(option){
		if(this.state.value === option && this.props.allowNull){
			option = null;
		}

		this.setState({value:option});

		if(this.props.onChange){
			this.props.onChange(option);
		}
	}
}
