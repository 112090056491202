import React, { Component } from 'react';
import LoadingScreen,{SavingDialog} from './loadingScreen';
import AssignmentTable from '../components/assignmentTable';
import RadioGroup from '../components/radioGroup';
import SectionCard from '../components/sectionCard';

import Model from '../model';

import '../css/event-assignments.css';


function shuffle(a) {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[a[i], a[j]] = [a[j], a[i]];
	}
	return a;
}
export default class EventAssignmentsScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: true,
			saving: false,
		};
	}

	componentDidMount(){
		this.groupID = this.props.match.params.groupID;
		this.eventID = this.props.match.params.eventID;

		Model.getEvent(this.groupID, this.eventID, true, true, true).then(event => {
			for(let participantID in event.participants){
				event.participants[participantID].id = participantID;
			}
			if(!event.participants) event.participants = {};
			if(!event.judges) event.judges = {};
			if(!event.categories) event.categories = {};

			let assignments = {};
			for(let judgeID in event.judges){
				let assignmentsTable = {};
				let judge = event.judges[judgeID];

				for(let participantID in event.participants){
					if(judge.assignments[participantID]){
						assignmentsTable[participantID] = true;
					}else{
						assignmentsTable[participantID] = false;
					}
				}
				assignments[judgeID] = assignmentsTable;
			}

			this.setState({
				loading: false,
				assignments: assignments,
				compactView: true,
				autoJudgesPerParticipant: 3,
				categoryFilter: '',
				...event,
			});
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		if(Object.keys(this.state.judges).length === 0 || Object.keys(this.state.participants).length === 0){
			return <div className="page event-assignments">You'll need to add judges and participants first :)</div>
		}

		return (
			<div className="page event-assignments">
				{ this.state.saving ? <SavingDialog onDismiss={()=>this.setState({saving:false})}/> : null }
				<h2>{this.state.name} Assignments</h2>

				<SectionCard title="Options">
					<div className="responsive-form">
					<label>View mode</label>
							<div>
								<RadioGroup
									choices={['Compact', 'Expanded']}
									value={this.state.compactView ? 'Compact' : 'Expanded'}
									onChange={(val) => this.setState({compactView: val === 'Compact'})}
								/>
							</div>

							<label>Categories</label>
							<div>
								<select className="form-control" onChange={(e)=>this.setState({categoryFilter: e.target.value})}>
									<option value=''>All assigned categories</option>
									{
										Object.keys(this.state.categories).map((categoryID) => {
											let category = this.state.categories[categoryID];
											return <option key={categoryID} value={categoryID}>{category.name}</option>;
										})
									}
								</select>
							</div>

							<label>Global actions</label>
							<div>
								<button className="btn btn-sm btn-secondary" onClick={()=>this.markAllAssignments(false)}>Clear all</button>
								<button className="btn btn-sm btn-secondary" onClick={()=>this.markAllAssignments(true)}>Mark all</button>
							</div>

							<label>Auto-fill</label>
							<div>
								<div className="col-md-4 col-sm-10 input-group">
									<input className="form-control form-control" type="number" value={this.state.autoJudgesPerParticipant} onChange={(e)=>this.setState({autoJudgesPerParticipant:e.target.value})} />
									<div className="input-group-append"><span className="input-group-text">judges per participant</span></div>
								</div>
								<div className="offset-sm-2 col-sm-10" style={{paddingTop: '0.5em'}}>
									<button className="btn btn-sm btn-secondary" onClick={()=>this.autoAssign(true)}>Randomize</button>
									<button className="btn btn-sm btn-secondary" onClick={()=>this.autoAssign(false)}>Cascade</button>
								</div>
							</div>
					</div>
				</SectionCard>

				<SectionCard title="Assignments">
					<AssignmentTable
						judges={this.state.judges}
						categoryFilter={this.state.categoryFilter}
						categories={this.state.categories}
						participants={this.state.participants}
						assignments={this.state.assignments}
						editMode={true}
						onAssignmentChanged={(judge, participant)=>this.onAssignmentChanged(judge, participant)}
						compactView={this.state.compactView}
					/>
					<p><em>Note: ✓ indicates that the judge has checked in.</em></p>

					<div className="button-box">
						<button className="btn btn-link" onClick={()=>this.cancel()}>Cancel</button>
						<button className="btn btn-primary" onClick={()=>this.save()}>Save</button>
					</div>
				</SectionCard>
			</div>
		);
	}

	onAssignmentChanged(judge, participant){
		let assignments = this.state.assignments;
		assignments[judge.id][participant.id] = !assignments[judge.id][participant.id];

		this.setState({assignments: assignments});
	}

	cancel(){
		this.props.history.goBack();
	}

	save(){
		this.setState({saving:true});
		Model.saveEventAssignments(this.groupID, this.eventID, this.state.assignments).then(() => {
			this.props.history.goBack();
		});
	}

	autoAssign(randomize){
		if(this.state.autoJudgesPerParticipant > Object.keys(this.state.judges).length){
			alert('You cannot assign more judges than the number that exist');
			return;
		}
		if(!isFinite(this.state.autoJudgesPerParticipant) || isNaN(parseFloat(this.state.autoJudgesPerParticipant))){
			alert('You must enter a number');
			return;
		}
		this.markAllAssignments(false);

		let participantIDs = Object.keys(this.state.participants);
		let judgeIDs = [];
		while(judgeIDs.length < participantIDs.length * this.state.autoJudgesPerParticipant){
			let uniqueJudgeIDs = Object.keys(this.state.judges).reverse();
			if(randomize){
				uniqueJudgeIDs = shuffle(uniqueJudgeIDs);
			}
			judgeIDs = judgeIDs.concat(uniqueJudgeIDs);
		}

		let assignments = this.state.assignments;

		for(let pid of participantIDs){
			for(let i=0; i<this.state.autoJudgesPerParticipant; i++){
				let jid = judgeIDs.shift();
				while(assignments[jid][pid]){
					judgeIDs.push(jid);
					jid = judgeIDs.shift();
				}
				assignments[jid][pid] = true;
			}
		}
	}

	markAllAssignments(asAssigned){
		let assignments = this.state.assignments;
		for(let jid in assignments){
			for(let pid in assignments[jid]){
				assignments[jid][pid] = asAssigned;
			}
		}

		this.setState({assignments: assignments});
	}
}
