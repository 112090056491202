import React, { Component } from 'react';
import {
	BrowserRouter,
	Route,
	Link,
	Switch
} from 'react-router-dom';

import './css/App.css';
import logo from './graphics/judge-zed-logo.png';

import HomeScreen from './screens/homeScreen';
import LoginScreen from './screens/loginScreen';
import ProfileScreen from './screens/profileScreen';
import GroupScreen from './screens/groupScreen';
import EventScreen from './screens/eventScreen';
import EventLandingScreen from './screens/eventLandingScreen';
import JudgingScreen from './screens/judgingScreen';
import InviteScreen from './screens/inviteScreen';
import RubricScreen from './screens/rubricScreen';
import ScoreScreen from './screens/scoreScreen';
import EventAssignmentsScreen from './screens/eventAssignmentsScreen';

import SectionCard from './components/sectionCard';
import NotFoundScreen from './screens/notFoundScreen';

import env from './env';
import Model from './model';

class App extends Component {
	constructor(){
		super();

		this.pages = {
			'/': (props) => <HomeScreen {...this.bindMessaging(props)}/>,
			'/login': (props) => <LoginScreen {...this.bindMessaging(props)}/>,
			'/me': (props) => <ProfileScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID': (props) => <GroupScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID/events/:eventID': (props) => <EventLandingScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID/events/:eventID/admin': (props) => <EventScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID/events/:eventID/assignments': (props) => <EventAssignmentsScreen {...this.bindMessaging(props)}/>,
			'/groups/:groupID/events/:eventID/score': (props) => <ScoreScreen {...this.bindMessaging(props)}/>,
			'/judging/:groupID/:eventID/:judgeID': (props) => <JudgingScreen {...this.bindMessaging(props)}/>,
			'/invite/:groupID/:eventID/:judgeID': (props) => <InviteScreen {...this.bindMessaging(props)}/>,
			'/judging/:groupID/:eventID/:judgeID/:participantID': (props) => <RubricScreen {...this.bindMessaging(props)}/>,
		}

		this.state = {
			errors: [],
			messages: [],
			loggedIn: false,
			showHelp: false,
			groups: [],
			groupsEvents: false,
			sidebarVisible: window.innerWidth > 768,
		};
	}

	componentDidMount(){
		if(env.name !== 'production'){
			document.title = env.name.toUpperCase() + ' : ' + document.title;
		}

		Model.auth.onAuthStateChanged((user) => {
			this.setState({loggedIn:Boolean(user)});
			if(user){
				Model.getGroups().then((groups) => {
					this.setState({groups: groups})
					let promises = [];
					for(let group of groups){
						promises.push(Model.getEvents(group.id));
					}
					return Promise.all(promises);
				}).then((groupsEvents) => {
					this.setState({groupsEvents: groupsEvents});
				});
			}
		});
	}

	bindMessaging(props){
		return {
			...props,
			onMessage: (msg) => this.onMessage(msg),
			onError: (error, msg) => this.onError(error, msg),
		};
	}

	render() {
		return (
			<BrowserRouter>
				<div className="App">
					{
						env.name === 'production' ? null:(
							<div className={'corner-ribbon ' + env.name}>{env.name}</div>
						)
					}

					{
						this.state.loggedIn ? (
							<div className={'sidebar'+(this.state.sidebarVisible?'':' hidden')}>
								<header>
									<a href="/">
										<img src={logo} width="128" height="128" alt="Judge Zed logo"/>
									</a>
									<h1>Judge Zed</h1>
								</header>
								<nav>
									<Link to='/me' onClick={()=>this.hideMobileMenu()}>Home</Link>

									<a href="#signout" onClick={()=>this.signout()}>Sign out</a>
									{
										this.state.groups.map((group, i) => {
											return <div key={group.id}>
													<Link className="section-header" to={`/groups/${group.id}`} title={group.name} onClick={()=>this.hideMobileMenu()}>{group.name}</Link>
													{
														(this.state.groupsEvents && this.state.groupsEvents[i]) ? (
															this.state.groupsEvents[i].map(event => {
																return <Link key={event.id}
																		onClick={()=>this.hideMobileMenu()}
																		to={`/groups/${group.id}/events/${event.id}/admin`}
																		className="event-link"
																	>{event.name}</Link>
															})
														) : null
													}
												</div>
										})
									}
									{
										this.state.loggedIn ? (
											<div className="uid">UID: {Model.auth.currentUser.uid}</div>
										):null
									}
								</nav>
								{
									this.state.loggedIn ? (
										<SidebarToggle
											className="right"
											visible={this.state.sidebarVisible}
											onClick={(e) => this.setState({sidebarVisible: !this.state.sidebarVisible})}
										/>
									):null
								}
							</div>
						):null
					}

					<div className={'content'+((this.state.sidebarVisible && this.state.loggedIn)?'':' full')}>
						{
							this.state.loggedIn ? (
								<SidebarToggle
									className="left"
									visible={this.state.sidebarVisible}
									onClick={(e) => this.setState({sidebarVisible: !this.state.sidebarVisible})}
								/>
							):null
						}
						{
							(this.state.errors && this.state.errors.length > 0) ? (
								<SectionCard title="Something went wrong :(" className="error-card" buttons={<button onClick={(e)=>this.setState({errors:[]})} className="btn btn-danger btn-sm">Dismiss errors</button>}>
									{
										this.state.errors.map((error, id) => {
											return <ErrorDisplay key={id} error={error} />
										})
									}
								</SectionCard>
							):null
						}
						{
							(this.state.messages && this.state.messages.length > 0) ? (
								<SectionCard title="Info" className="message-card" buttons={<button onClick={(e)=>this.setState({messages:[]})} className="btn btn-link btn-sm">Dismiss messages</button>}>
									<ul>
										{
											this.state.messages.map((message, id) => {
												return <li key={id}>{message}</li>
											})
										}
									</ul>
								</SectionCard>
							):null
						}
						<Switch>
							{
								Object.keys(this.pages).map((routePath) => {
									return <Route key={routePath}
										exact
										path={routePath}
										component={this.pages[routePath]}
									/>;
								})
							}
							<Route component={NotFoundScreen} />
						</Switch>
					</div>
				</div>
			</BrowserRouter>
		)
	}

	hideMobileMenu(){
		if(window.innerWidth <= 768){
			this.setState({sidebarVisible: false});
		}
	}

	onMessage(message){
		this.state.messages.push(message);
		this.setState({messages: this.state.messages});

		if(this.autoClearMessages){
			this.messageClearTimeout = window.setTimeout(()=>{
				this.clearMessages()
			}, 3000);
		}
	}

	onError(error, msg){
		console.error('ERROR [' + msg + ']', JSON.stringify(error));

		this.setState((state) => {
			let errors = [...state.errors];
			errors.push({
				userMessage: msg,
				...error
			});
			return {errors: errors};
		});
	}

	clearMessages(){
		window.clearTimeout(this.messageClearTimeout);
		this.setState({messages:[]});
	}

	clearErrors(){
		this.setState({errors:[]});
	}

	signout(){
		Model.signOut().then(()=>{
			window.location.href = '/';
		});
	}

	showHelp(){
		this.setState({showHelp: true});
	}
}


class SidebarToggle extends Component{
	render() {
		return <div
				className={'sidebar-toggle '+this.props.className}
				onClick={this.props.onClick}
				title="Toggle sidebar"
			>
				<div>{this.props.visible ? '«' : '»'}</div>
			</div>
	}
}

class ErrorDisplay extends Component {
	render(){
		let message = this.props.error.userMessage;
		if(!message){
			if(this.props.error.code === 'permission-denied'){
				message = 'You do not have access to this resource';
			}else{
				message = 'An error has occurred';
			}
		}

		return <div className="error-row">
				<details>
					<summary>{message}</summary>
					<ul>
						{this.props.error.message ? <li>{this.props.error.message}</li> : null}
						<li>
							<code style={{fontSize: '90%', whiteSpace: 'pre'}}>{JSON.stringify(this.props.error, null, 5)}</code>
						</li>
					</ul>
				</details>
			</div>;
	}
}

export default App;
