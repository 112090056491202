import React, { Component } from 'react';

import LoadingScreen, {SavingDialog} from './loadingScreen';
import SectionCard from '../components/sectionCard';
import Model from '../model';
import RadioGroup from '../components/radioGroup';

import ReactMarkdown from 'react-markdown';
import '../css/rubric.css';

export default class RubricScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: true,
			saving: false,
		};
	}

	componentDidMount(){
		this.groupID = this.props.match.params.groupID;
		this.eventID = this.props.match.params.eventID;
		this.judgeID = this.props.match.params.judgeID;
		this.participantID = this.props.match.params.participantID;

		// load info from model
		// update state to not loading
		let promises = [
			Model.getParticipantInfoForJudge(this.groupID, this.eventID, this.judgeID, this.participantID),
			Model.getEvent(this.groupID, this.eventID),
		];
		Promise.all(promises).then(([participantInfo, eventInfo]) => {
			if(!participantInfo.scores){
				participantInfo.scores = {};
			}

			let rubric = eventInfo.categories[participantInfo.categoryID].rubric;

			let maxPossible = 0;
			for(let scorable of rubric){
				maxPossible += scorable.max;
			}

			this.setState({
				participantInfo: participantInfo,
				eventInfo: eventInfo,
				rubric: rubric,
				loading: false,
				maxPossible: maxPossible,
			});
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	getPoints(includeBlanksInTotal){
		let points = 0;
		let maxPossible = 0;

		for(let scorable of this.state.rubric){
			if(scorable.name in this.state.participantInfo.scores){
				let score = this.state.participantInfo.scores[scorable.name];
				if(score !== 'NA'){
					if(score !== null || includeBlanksInTotal){
						maxPossible += scorable.max;
						points += score;
					}
				}
			}else if(includeBlanksInTotal){
				maxPossible = scorable.max;
			}
		}

		return [points, maxPossible];
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		let [score, maxPossible] = this.getPoints(true);

		let linkContent = null;
		if(this.state.participantInfo.url){
			let ytVideoID = null;
			let url = new URL(this.state.participantInfo.url);
			if(url.hostname === 'www.youtube.com' || url.hostname === 'youtube.com'){
				ytVideoID = url.searchParams.get('v');
			}
			if(url.hostname === 'youtu.be'){
				ytVideoID = url.pathname;
			}

			if(ytVideoID){
				linkContent = <iframe width="560" height="315" src={'https://www.youtube.com/embed/'+ytVideoID} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="1"></iframe>
			}else{
				linkContent = <p><a href={this.state.participantInfo.url} target="_blank">Link</a></p>;
			}
		}

		let details = this.state.participantInfo.details;

		return <div className="page rubric-screen">
			{ this.state.saving ? <SavingDialog onDismiss={()=>this.setState({saving:false})}/> : null }
			<SectionCard title={this.state.participantInfo.projectTitle}>
				<h4>{this.state.participantInfo.name}</h4>
				{linkContent}
				<ReactMarkdown>{this.state.participantInfo.details}</ReactMarkdown>
			</SectionCard>

			{
				this.state.rubric.map((scorable)=> {
					return <ScorableCard key={scorable.name}
						{...scorable}
						onChange={(value)=>this.setScore(scorable.name, value)}
						value={this.state.participantInfo.scores[scorable.name]}
					/>
				})
			}

			<SectionCard title={`Total: ${score}/${maxPossible}`}>
				{
					this.state.eventInfo.commentsEnabled ? (
						<div>
							Comments to participant<br/>
							<textarea
								className="form-control"
								value={this.state.participantInfo.comments || ''}
								onChange={(e)=>this.setComments(e.target.value)}
							/>
						</div>
					):null
				}

				<div className="button-box">
					<button className="btn btn-link" onClick={()=>this.cancel()}>Cancel</button>
					<button className="btn btn-primary" onClick={()=>this.save()}>Save</button>
				</div>
			</SectionCard>
		</div>
	}

	setScore(name, value){
		let scores = {...this.state.participantInfo.scores};
		scores[name] = value;

		this.setState(previousState => ({
			participantInfo: {
				...previousState.participantInfo,
				scores: scores,
			},
		}));
	}

	setComments(comments){
		this.setState(previousState => ({
			participantInfo: {
				...previousState.participantInfo,
				comments: comments,
			},
		}));
	}

	cancel(){
		this.props.history.goBack();
	}

	save(){
		this.setState({saving: true});

		let [score, maxPossible] = this.getPoints(false);

		Model.saveJudgingScores(this.groupID, this.eventID, this.judgeID, this.participantID, this.state.participantInfo.scores, this.state.participantInfo.comments, maxPossible)
			.then(()=>this.props.history.goBack())
			.catch((exc) => {
				this.props.onError(exc, 'Failed to save scores. An event organizer may have closed judging.');
				this.setState({saving: false});
			});
	}
}

class ScorableCard extends Component{
	constructor(props){
		super();

		this.options = [];

		if(props.allowNA){
			this.options.push('NA');
		}

		for(let i=props.min; i<=props.max; i++){
			this.options.push(i);
		}

		this.state = {
			...props,
			expanded: (props.value == null),
		};
	}

	render(){
		let helpButton = this.state.description ? (
			<button className="btn btn-secondary" onClick={()=>this.toggleExpanded()}>&nbsp;?&nbsp;</button>
		) : null;

		return <SectionCard title={this.state.name} key={this.state.name} buttons={helpButton}>
			<RadioGroup
				choices={this.options}
				onChange={this.props.onChange}
				value={this.state.value}
				allowNull={true}
			/>
			{
				this.state.description ? (
					<div style={{display: (this.state.expanded ? 'block' : 'none')}} className="scorable-description">
						<ReactMarkdown>{this.state.description}</ReactMarkdown>
						<div style={{textAlign:'center'}}>
							<div className="btn btn-secondary btn-sm" onClick={()=>this.toggleExpanded()}>Hide details</div>
						</div>
					</div>
				) : null
			}
		</SectionCard>
	}

	toggleExpanded(){
		this.setState({expanded:!this.state.expanded});
	}
}