import React, { Component } from 'react';

export default class ProgressBar extends Component {
	render(){
		let progress = Math.round(this.props.progress*100);
		
		return (
			<div className="progressBar">
				<div className="progressBarFill" role="progressbar" style={{width:progress+'%'}} />
				{this.props.children}
			</div> 
		)
	}
}
