import React, { Component } from 'react';

import ModalDialog from '../components/modalDialog';
import logo from '../graphics/judge-zed-logo.png';

import Model from '../model';
import QRCode from 'qrcode.react'

import '../css/home.css';

export default class HomeScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: false,
			judging: false,
			scanCode: true,
			uid: undefined,
		};
	}

	componentDidMount(){
		Model.auth.onAuthStateChanged((user) => {
			if(user){
				this.setState({uid:user.uid})
			}
		});
	}

	render() {
		return (
			<div className="page home-screen">
				<header>
					<img src={logo} width="256" height="256" alt="Judge Zed logo"/>
					<h1>Judge Zed</h1>
					<div className="subtitle">A de-biasing scoring platform</div>
				</header>

				<div className="main-button-box">
					{/*
						<button className="btn btn-primary" onClick={()=>this.showJudgeEmailInfo()}><div>Online Event<br/>Judges</div></button>
					*/}
					<button className="btn btn-primary" onClick={()=>this.showJudgeCode()}><div>In-person<br/>Event Judges</div></button>
				</div>

				<div style={{position:'absolute', top: '1em', right: '1em'}}>
					<button className="btn btn-primary" onClick={()=>this.setState({adminLogin: true})}><div>Admin Login</div></button>
				</div>

				<div className="site-description">
					<h3>What is Judge Zed?</h3>
					<p>Judge Zed is a scoring platform for events that involve multiple judges scoring multiple participants. For events with many competitors, it may be impossible for every judge to score every entry. To avoid punishing or promoting competitors based on the judges assigned to them, Judge Zed mathematically normalizes raw scores using the mean and standard deviation of each judge individually.</p>

					<p>Normalized scoring corrects for radical judges, so all participants are ranked fairly without requiring every judge to score every participant.</p>

					<h3>Want to use Judge Zed for your event?</h3>
					<p>Do you have an event coming up that could use something like Judge Zed? That's great - we'd love to be a part of your event! Send a message to <a href="mailto:inquiries@judgezed.com">inquiries@judgezed.com</a> :)</p>
				</div>

				{
					this.state.judging ? (
						<div>
							<ModalDialog
								title="Show this code at the check-in table"
								footer={
									<div>
										<button className="btn btn-default btn-link" onClick={()=>this.setState({judging: false})}>Cancel</button>
									</div>
								}
							>
								{
									(this.state.uid) ? (
										<div style={{textAlign:'center'}}>
											<QRCode
												value={this.state.uid}
												size={1024}
												style={{width:'60%', height:'auto',margin:'2em',display:'inline-block'}}
											/>
											<br/>{this.state.uid}
										</div>

									) : <div>One moment...</div>
								}
							</ModalDialog>
						</div>
					) : null
				}

				{
					this.state.adminLogin ? (
						<form onSubmit={(e)=>{ e.preventDefault(); return false; }}>
							<ModalDialog
								title="Admin Login"
								footer={
									<div>
										<button type="submit" className="btn btn-primary" style={{float:'right'}} value="Login" onClick={()=>this.adminLogin()}>Login</button>
										<button className="btn btn-default btn-link" onClick={()=>this.setState({adminLogin:false})}>Cancel</button>
									</div>
								}
							>
							 	<div className="responsive-form">
									<label>Email</label>
									<input className="form-control" type="email" placeholder="email@example.com" onChange={(e)=>this.setState({email:e.target.value})}/>

									<label>Password</label>
									<input className="form-control" type="password" placeholder="Password" onChange={(e)=>this.setState({password:e.target.value})}/>
								</div>
							</ModalDialog>
						</form>
					) : null
				}

				{
					this.state.showEmailInfoPopup ? (
						<div>
							<ModalDialog
								title="Online events"
								footer={
									<div>
										<button className="btn btn-primary" style={{float:'right'}} onClick={()=>this.setState({showEmailInfoPopup:false})}>OK</button>
									</div>
								}
							>
								<p>For online events, you will receive an email once judging has been opened by an event organizer.<br/>That email will contain a unique link to your judging form.</p>
								<p>You may need to check your spam/junk folders.</p>

							</ModalDialog>
						</div>
					):null
				}
			</div>
		);
	}

	showJudgeCode(){
		this.setState({judging: true});
		Model.initiateJudgeAuth(judgeInfo => {
			this.props.history.push('/judging/' + judgeInfo.groupID + '/' + judgeInfo.eventID + '/' + judgeInfo.judgeID + '/');
		});
	}

	showJudgeEmailInfo(){
		this.setState({showEmailInfoPopup: true});
	}

	adminLogin(){
		var email = this.state.email;
		var password = this.state.password;

		Model.auth.signInWithEmailAndPassword(email, password)
			.then(() => {
				this.props.history.push('/me');
			}).catch((error) => {
				alert('Login failed :(');
			});
	}
}
