import React, { Component } from 'react';

export default class ModalDialog extends Component {
	render() {
		return (
			<div className="modal">

				<div className="modal-dialog" >
					<div className="modal-content">

						<div className="modal-header">
							{this.props.title}
						</div>

						<div className="modal-body">
							{this.props.children}
						</div>

						<div className="modal-footer">
							{this.props.footer}
						</div>
						
					</div>
				</div>
			</div>
		);
	}
}