import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';

import env from './env';

firebase.initializeApp(env.config);

function snapshotCollectionToArray(snapshot){
	return snapshot.docs.map((doc) => { return {'id':doc.id, ...doc.data()}});
}

function snapshotCollectionToDictionary(snapshot){
	let snapDict = {};
	for(let doc of snapshot.docs){
		snapDict[doc.id] = doc.data();
		if(!snapDict[doc.id].id) snapDict[doc.id].id = doc.id;
	}
	return snapDict;
}

function makeUndefinedsNull(obj){
	for(let f in obj){
		if(obj[f] === undefined){
			obj[f] = null;
		}
	}
	return obj;
}

function updateWholeCollection(collectionRef, collectionObj, newIDRegex, fieldsToSaveAsCollections, batch){
	let promises = [];

	for(let objectID in collectionObj){
		let obj = makeUndefinedsNull(collectionObj[objectID]);

		if(obj.deleted){
			let objRef = collectionRef.doc(objectID);
			if(batch){
				batch.delete(objRef);
			}else{
				promises.push(objRef.delete());
			}
		}else{
			if(fieldsToSaveAsCollections){
				for(let field of fieldsToSaveAsCollections){
					let subCollection = obj[field];
					updateWholeCollection(collectionRef.doc(objectID).collection(field), subCollection, undefined, undefined, batch);

					delete obj[field];
				}
			}

			if(batch){
				if(newIDRegex && newIDRegex.test(objectID)){
					batch.set(collectionRef.doc(), obj);
				}else{
					batch.set(collectionRef.doc(objectID), obj, {merge:true});
				}
			}else{
				if(newIDRegex && newIDRegex.test(objectID)){
					promises.push(collectionRef.add(obj));
				}else{
					promises.push(collectionRef.doc(objectID).set(obj, {merge:true}));
				}
			}
		}
	}

	if(promises.length > 0){
		return Promise.all(promises);
	}
}

function stripExcept(collection, keys){
	let data = {};
	for(let id in collection){
		data[id] = {};
		for(let field of keys){
			if(collection[id][field] !== undefined){
				data[id][field] = collection[id][field];
			}
		}
	}
	return data;
};

class Model {
	constructor(){
		this.loggedIn = undefined;
		this.auth = firebase.auth();
		this.db = firebase.firestore();
		this.serverFunctions = firebase.functions();

		if(env.name === 'dev'){
			this.auth.useEmulator('http://localhost:9099/');
			this.serverFunctions.useEmulator('localhost', 5001);
			this.db.useEmulator('localhost', 8080);
		}

		this.auth.onAuthStateChanged((user) => {
			this.loggedIn = Boolean(user);
		});
	}

	waitForLogin(){
		return new Promise((resolve, reject) => {
			let checker = setInterval(()=>{
				if(this.loggedIn !== undefined){
					clearInterval(checker);
					if(this.loggedIn === false){
						alert('You must be logged in to access this.');
						window.location.href = '/';
					}else{
						resolve(this.loggedIn);
					}
				}
			}, 100);
		});
	}

	signOut(){
		return this.auth.signOut();
	}

	async getMyInfo(){
		await this.waitForLogin();

		let basicUserData = this.auth.currentUser;

		let userID = this.auth.currentUser.uid;
		let userRef = await this.db.collection('users').doc(userID).get();
		let extraUserData = userRef.data();

		let judgeRef = await this.db.collection('judges').doc(userID).get();
		let judgeData = judgeRef.data();

		return firebase.auth().currentUser.getIdTokenResult().then((idTokenResult) => {
			return {
				...basicUserData,
				...extraUserData,
				token: idTokenResult,
				superAdmin: !!(idTokenResult.claims.superAdmin),
				judgeData: judgeData
			};
		})

	}

	async getGroups(){
		/*
			Retrieve a list of groups for which the authenticated user is enrolled

			Returns [{id:'...', name:'...'}, ...]
		*/

		await this.waitForLogin();
		let myInfo = await this.getMyInfo();

		let userGroupsRef;
		if(myInfo.superAdmin){
			userGroupsRef = this.db.collection('/groups');
		}else{
			let userID = this.auth.currentUser.uid;
			userGroupsRef = this.db.collection('/users/' + userID + '/groups');
		}
		let enrollmentSnapshot = await userGroupsRef.get();

		if(enrollmentSnapshot.size > 0){
			var groups = [];
			enrollmentSnapshot.forEach((doc) => {
				groups.push({
					'id': doc.id,
					'name': doc.data().name,
				});
			});

			groups.sort((a,b) => a.name > b.name);

			return groups;
		}else{
			return [];
		}
	}

	async saveMe(info){
		await this.waitForLogin();

		let fields = ['displayName', 'email', 'photoUrl'];
		let values = {};

		for(let key of Object.keys(info)){
			if(fields.indexOf(key) > -1){
				values[key] = info[key];
			}
		}

		let extraUserRef = this.db.collection('users').doc(this.auth.currentUser.uid);
		await extraUserRef.set(values, { merge:true});
	}

	async getUsers(groupID){
		await this.waitForLogin();

		let groupUsersRef = this.db.collection(`groups/${groupID}/users`);

		let groupUsersSnapshot = await groupUsersRef.get();
		let users = groupUsersSnapshot.docs.map((doc) => { return {'id':doc.id, ...doc.data()}});

		return users;
	}

	async getInvites(groupID){
		await this.waitForLogin();

		let invitesRef = this.db.collection(`groups/${groupID}/invites`);

		let invitesSnapshot = await invitesRef.orderBy('creationTime').get();
		let invites = invitesSnapshot.docs.map((doc) => { return {'id':doc.id, ...doc.data()}});

		return invites;
	}

	async getGroupInfo(groupID) {
		let snapshot = await this.db.collection('groups').doc(groupID).get();
		return snapshot.data();
	}

	async getEvents(groupID) {
		/* Retrieve a list of events for the specified group
				Returns [{id:'...', ...}, ...]
		*/
		await this.waitForLogin();

		let eventsRef = this.db.collection('groups').doc(groupID).collection('events');
		return snapshotCollectionToArray(await eventsRef.orderBy('name').get());
	}

	async getEvent(groupID, eventID, loadJudges, loadParticipants, loadAssignments){
		let eventRef = this.db.collection('groups').doc(groupID).collection('events').doc(eventID);
		let event = (await eventRef.get()).data();

		let promises = [];
		promises.push(eventRef.collection('categories').orderBy('name').get());

		if(loadJudges){
			promises.push(eventRef.collection('judges').orderBy('name').get());
		}else{
			promises.push(new Promise(function(resolve, reject) { resolve(undefined); }));
		}

		if(loadParticipants){
			promises.push(eventRef.collection('participants').orderBy('name').get());
		}else{
			promises.push(new Promise(function(resolve, reject) { resolve(undefined); }));
		}

		let [categoriesSnap, judgesSnap, participantsSnap] = await Promise.all(promises);

		event.categories = snapshotCollectionToDictionary(categoriesSnap);

		if(judgesSnap){
			event.judges = snapshotCollectionToDictionary(judgesSnap);
			if(loadAssignments){
				let assignmentPromises = [];
				let judgeIDs = [];
				for(let judgeID in event.judges){
					judgeIDs.push(judgeID);
					assignmentPromises.push(eventRef.collection('judges/'+judgeID+'/assignments').get());
				}
				let assignmentSnaps = await Promise.all(assignmentPromises);

				for(let i in judgeIDs){
					let judgeID = judgeIDs[i];
					event.judges[judgeID].assignments = snapshotCollectionToDictionary(assignmentSnaps[i]);
				}
			}
		}

		if(participantsSnap){
			event.participants = snapshotCollectionToDictionary(participantsSnap);
		}

		if(!event.rubric){
			event.rubric = [];
		}

		return event;
	}

	updateEventDetails(groupID, eventID, event){
		event = makeUndefinedsNull(event);
		let eventRef = this.db.doc(`groups/${groupID}/events/${eventID}`);
		return eventRef.update(event);
	}

	saveCategories(groupID, eventID, categories){
		categories = stripExcept(categories, ['name', 'rubric']);

		for(let categoryID in categories){
			for(let idx = categories[categoryID].rubric.length; idx--; idx>=0){
				if(categories[categoryID].rubric[idx].deleted){
					categories[categoryID].rubric.splice(idx, 1);
				}
			}
		}

		let batch = this.db.batch();
		let categoryCollection = this.db.collection(`groups/${groupID}/events/${eventID}/categories`);

		updateWholeCollection(categoryCollection, categories, /^ [Nn]ew-.*/, [], batch);

		return batch.commit();
	}

	saveJudges(groupID, eventID, judges){
		judges = stripExcept(judges, ['name', 'details', 'deleted', 'email']);
		let batch = this.db.batch();
		let judgesCollection = this.db.collection(`groups/${groupID}/events/${eventID}/judges`);

		updateWholeCollection(judgesCollection, judges, /^ [Nn]ew-.*/, [], batch);

		return batch.commit();
	}

	saveParticipants(groupID, eventID, participants){
		participants = stripExcept(participants, ['name', 'details', 'deleted', 'email', 'url', 'projectTitle', 'categoryID']);
		for(let participantID in participants){
			if(participants[participantID].url){
				let pattern = /^(ht|f)tps?:\/\//;
				if(!pattern.test(participants[participantID].url.toLowerCase())){
					participants[participantID].url = 'https://' + participants[participantID].url;
				}
			}
		}

		let batch = this.db.batch();
		let participantsCollection = this.db.collection(`groups/${groupID}/events/${eventID}/participants`);

		updateWholeCollection(participantsCollection, participants, /^ [Nn]ew-.*/, [], batch);

		return batch.commit();
	}

	async saveEventAssignments(groupID, eventID, assignments){
		await this.waitForLogin();

		let event = await this.getEvent(groupID, eventID, true, true, true);
		let judges = event.judges;
		for(let judgeID in assignments){
			let judge = judges[judgeID];

			let judgeAssignments = assignments[judgeID];
			for(let participantID in judgeAssignments){
				if(judgeAssignments[participantID]){
					judge.assignments[participantID] = {
						...judge.assignments[participantID],
						...event.participants[participantID]
					};
				}else if(judge.assignments[participantID]){
					judge.assignments[participantID].deleted = true;
				}
			}
		}

		let batch = this.db.batch();
		let eventRef = this.db.collection('groups').doc(groupID).collection('events').doc(eventID);
		updateWholeCollection(eventRef.collection('judges'), judges, /^ [Nn]ew-.*/, ['assignments'], batch);
		await batch.commit();
	}

	async deleteEvent(groupID, eventID){
		await this.waitForLogin();

		let eventRef = this.db.collection('groups').doc(groupID).collection('events').doc(eventID);
		await eventRef.delete();
	}

	async loginJudge(groupID, eventID, judgeID){
		if(!this.auth.currentUser){
			await this.auth.signInAnonymously();
		}

		let serverLogin = this.serverFunctions.httpsCallable('loginJudge');
		return await serverLogin({'groupID':groupID, 'eventID':eventID, 'judgeID':judgeID});
	}

	async initiateJudgeAuth(onApproved){
		if(!this.auth.currentUser){
			await this.auth.signInAnonymously();
		}

		let ref = this.db.collection('judges').doc(this.auth.currentUser.uid);
		ref.onSnapshot((doc) => {
			let judgeInfo = doc.data();
			if(judgeInfo){
				onApproved(judgeInfo);
			}
		})
	}

	async approveJudge(groupID, eventID, judgeID, uid){
		let ref = this.db.doc('groups/'+groupID+'/events/'+eventID+'/judges/'+judgeID);
		await ref.set({'uid': uid}, {merge:true});

		ref = this.db.doc('judges/'+uid);
		let info = {
			'groupID': groupID,
			'eventID': eventID,
			'judgeID': judgeID
		};
		await ref.set(info, {merge:true});
	}

	async unapproveJudge(groupID, eventID, judgeID){
		let promises = [];
		let ref = this.db.doc('groups/'+groupID+'/events/'+eventID+'/judges/'+judgeID);
		let judgeInfo = (await ref.get()).data();
		let uid = judgeInfo.uid;
		promises.push(ref.set({'uid': null}, {merge:true}));

		ref = this.db.doc('judges/'+uid);
		promises.push(ref.delete());

		await Promise.all(promises);
		return;
	}

	async getJudgingAssignments(groupID, eventID, judgeID){
		await this.waitForLogin();

		let ref = this.db.collection('groups/'+groupID+'/events/'+eventID+'/judges/'+judgeID+'/assignments').orderBy('projectTitle');
		let snapshot = await ref.get();

		return snapshotCollectionToDictionary(snapshot);
	}

	async getJudgeInfo(groupID, eventID, judgeID){
		await this.waitForLogin();

		let ref = this.db.doc('groups/'+groupID+'/events/'+eventID+'/judges/'+judgeID);
		let judge = (await ref.get()).data();

		return judge;
	}

	async getRubric(groupID, eventID, categoryID){
		await this.waitForLogin();

		let ref = this.db.doc(`groups/${groupID}/events/${eventID}/categories/${categoryID}`)

		return (await ref.get()).data();
	}

	async getCategories(groupID, eventID){
		await this.waitForLogin();

		let ref = this.db.collection(`groups/${groupID}/events/${eventID}/categories`).orderBy('name');
		return snapshotCollectionToDictionary(await ref.get());
	}

	async getParticipantInfoForJudge(groupID, eventID, judgeID, participantID){
		let assignments = await this.getJudgingAssignments(groupID, eventID, judgeID);
		return assignments[participantID];
	}

	async saveJudgingScores(groupID, eventID, judgeID, participantID, scores, comments, maxPossible){
		let total = 0;
		for(let category in scores){
			if(scores[category] !== 'NA'){
				total += scores[category];
			}
		}

		let ref = this.db.doc(`groups/${groupID}/events/${eventID}/judges/${judgeID}/assignments/${participantID}`);
		let data = {
			'rawScore': total,
			'maxPossible': maxPossible,
			'scores': scores,
			'comments': comments,
		};
		data = makeUndefinedsNull(data);
		await ref.update(data);
	}

	async getJudgingStatus(groupID, eventID){
		await this.waitForLogin();

		try{
			let ref = this.db.collection('groups/'+groupID+'/events/'+eventID+'/judges');
			let judges = snapshotCollectionToDictionary(await ref.get());

			for(let judgeID in judges){
				let assignments = snapshotCollectionToDictionary(await ref.doc(judgeID).collection('assignments').get());
				judges[judgeID].assignments = assignments;
			}
		}catch(exc){
			console.error(exc);
		}
	}

	async calculateScores(groupID, eventID){
		await this.waitForLogin();

		let calculateScores = this.serverFunctions.httpsCallable('calculateScores');
		return await calculateScores({groupID:groupID, eventID:eventID});
	}

	async sendSingleInvite(groupID, eventID, judgeID){
		await this.waitForLogin();

		let emailFunc = this.serverFunctions.httpsCallable('emailSingleJudgeLink');
		return await emailFunc({groupID:groupID, eventID:eventID, judgeID:judgeID});
	}

	sendInvites(groupID, eventID){
		let emailFunc = this.serverFunctions.httpsCallable('emailJudgeLinksForEntireEvent');
		return emailFunc({groupID:groupID, eventID:eventID});
	}

	async sendAllScoreSheets(groupID, eventID, sendZScores, sendComments){
		let emailFunc = this.serverFunctions.httpsCallable('emailScoreSheets');
		return (await emailFunc({groupID:groupID, eventID:eventID, sendZScores:sendZScores, sendComments:sendComments})).data;
	}

	async sendParticipantScoreSheets(groupID, eventID, participantID, sendZScores, sendComments){
		let emailFunc = this.serverFunctions.httpsCallable('emailSingleParticipantScoreSheets');
		return (await emailFunc({groupID:groupID, eventID:eventID, participantID:participantID, sendZScores:sendZScores, sendComments:sendComments})).data[0];
	}

	async createGroup(groupID, groupName){
		let docRef = this.db.doc(`groups/${groupID}`)
		return docRef.get().then( snap => {
			if(snap.exists){
				throw new Error('Group already exists!');
			}
			return docRef.set({name: groupName});
		});
	}

	createEvent(groupID, eventID, eventName){
		let docRef = this.db.doc(`groups/${groupID}/events/${eventID}`);
		return docRef.get()
			.then((snap) => {
				if(snap.exists){
					throw new Error(`The event ID "${eventID}" is already in use`);
				}
				return docRef.set({name: eventName});
			});
	}

	sendAdminInvite(groupID, email){
		return this.db.doc(`groups/${groupID}/invites/${email}`).set({
			creator: this.auth.currentUser.uid,
			creationTime: new Date()
		});
	}

	async copyEvent(srcGroupID, srcEventID, destGroupID, destEventID){
		await this.waitForLogin();

		let event = (await this.db.doc(`groups/${srcGroupID}/events/${srcEventID}`).get()).data();
		event.name = "COPY " + event.name;

		await this.db.doc(`groups/${destGroupID}/events/${destEventID}`).set(event);

		let participantsSnap = await this.db.collection(`groups/${srcGroupID}/events/${srcEventID}/participants`).get();

		let promises = [];
		for(let doc of participantsSnap.docs){
			promises.push(
				this.db.doc(`groups/${destGroupID}/events/${destEventID}/participants/${doc.id}`).set(doc.data())
			);
		}

		let judgesSnap = await this.db.collection(`groups/${srcGroupID}/events/${srcEventID}/judges`).get();
		for(let doc of judgesSnap.docs){
			promises.push(
				this.db.doc(`groups/${destGroupID}/events/${destEventID}/judges/${doc.id}`).set(doc.data())
			);

			let assignmentsSnap = await this.db.collection(`groups/${srcGroupID}/events/${srcEventID}/judges/${doc.id}/assignments`).get();
			for(let assignDoc of assignmentsSnap.docs){
				promises.push(
					this.db.doc(`groups/${destGroupID}/events/${destEventID}/judges/${doc.id}/assignments/${assignDoc.id}`).set(doc.data())
				);
			}
		}

		await Promise.all(promises);
	}
};

export default new Model();
