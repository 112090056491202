import React, { Component } from 'react';

export default class BigOlButton extends Component {
	render() {
		return (
			<div
				className={'big-ol-button' + (this.props.className ? (' '+this.props.className) : '')}
				onClick={this.props.onClick}
			>
				<div className="button-top">
					{this.props.top}
				</div>
				<div className="button-middle">
					{this.props.children}
				</div>
				<div className="button-bottom">
					{this.props.bottom}
				</div>
			</div>
		);
	}
}
