import React, { Component } from 'react';

export default class EmbeddedVideo extends Component {
	render(){
		let src = this.props.src;
		try{
			let url = new URL(this.props.src);

			if(['www.youtube.com', 'youtube.com', 'youtu.be'].indexOf(url.hostname) > -1){
				if(!url.pathname.startsWith('/embed/')){
					let ytVideoID = null;
					if(url.hostname === 'youtu.be'){
						ytVideoID = url.pathname;
					}else{
						ytVideoID = url.searchParams.get('v');
					}

					src = `https://www.youtube.com/embed/${ytVideoID}?autoplay=1`;
				}
			}

			return <iframe
					width="560" height="315"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen="1"
					{...this.props}
					src={src}
				>{this.props.children}</iframe>
		}catch(err){
			console.error('Failed to render video', err);
			return null;
		}
	}
}