import React, { Component } from 'react';

import LoadingScreen from './loadingScreen';
import SectionCard from '../components/sectionCard';
import ProgressBar from '../components/progressBar';

import * as Papa from 'papaparse';

import Model from '../model';
import '../css/score.css';

function calculateMeanAndStdDev(values){
	let sum = 0;
	let count = values.length;

	for(let value of values){
		sum += value;
	}

	let mean = sum/count;
	let std = 0;
	for(let value of values){
		std += ((value - mean)**2)/(count-1);
	}
	std = Math.sqrt(std);

	return [ mean, std ];
}

function countDecimals(decimal){
	let num = parseFloat(decimal);

	if(Number.isInteger(num) === true){
		return 0;
	}

	let text = num.toString();
	if(text.indexOf('e-') > -1) {
		let [base, trail] = text.split('e-');
		let deg = parseInt(trail, 10);

		return deg;
	}else{
		let index = text.indexOf(".");
		return text.length - index - 1; // Otherwise use simple string function to count
	}
}

export default class EventScreen extends Component {
	constructor(){
		super();
		this.state = {
			'loading': true,
			'nameFilter': '',
			'precision': .05,
			'roundingStep': .01,
		};
	}

	componentDidMount(){
		this.groupID = this.props.match.params.groupID;
		this.eventID = this.props.match.params.eventID;

		let categoryIDs = [];

		Model.getEvent(this.groupID, this.eventID, true, true, true).then(event => {
			let judgeCounts = new Map();
			let participantCounts = new Map();

			let scoresCompleted = 0;
			let scoresNeeded = 0;

			this.participantStatuses = new Map();

			for(let judgeID in event.judges){
				let judge = event.judges[judgeID];
				judge.scoresCompleted = 0;
				scoresNeeded += Object.keys(judge.assignments).length;
				judge.scoresNeeded = Object.keys(judge.assignments).length;

				for(let participantID in judge.assignments){
					judgeCounts.set(judgeID, (judgeCounts.get(judgeID)||0) + 1);
					participantCounts.set(participantID, (participantCounts.get(participantID)||0) + 1);

					if(this.participantStatuses.get(participantID) === undefined){
						this.participantStatuses.set(participantID, {
							total: 0,
							completed: 0,
							waitingFor: [],
						});
					}
					this.participantStatuses.get(participantID)['total']++;

					if(judge.assignments[participantID].scores){
						let rubricItemsCompleted = 0;

						for(let score of Object.values(judge.assignments[participantID].scores)){
							if(score !== null){
								rubricItemsCompleted++;
							}
						}

						let rubricItemsAvailable = Object.keys(event.categories[judge.assignments[participantID].categoryID].rubric).length;
						if(rubricItemsCompleted === rubricItemsAvailable){
							judge.scoresCompleted++;
							scoresCompleted++;
							this.participantStatuses.get(participantID)['completed']++;
						}else{
							this.participantStatuses.get(participantID)['waitingFor'].push(judge);
						}
					}else{
						this.participantStatuses.get(participantID)['waitingFor'].push(judge);
					}

					let categoryID = judge.assignments[participantID].categoryID;
					if(categoryID && categoryID !== '' && !categoryIDs.includes(categoryID)){
						categoryIDs.push(categoryID);
					}
				}
			}

			this.setState({
				loading: false,
				participantLoad: calculateMeanAndStdDev(Array.from(participantCounts.values())),
				judgingLoad: calculateMeanAndStdDev(Array.from(judgeCounts.values())),
				totalScoresCompleted: scoresCompleted,
				totalScoresNeeded: scoresNeeded,
				categoryIDs: categoryIDs,
				separateCategories: (categoryIDs.length > 0),
				...event,
			});
		}).catch((exc) => {
			this.props.onError(exc);
		});
	}

	calcScoresByRounded(){
		let zScores = [];
		for(let participantID in this.state.zScores){
			zScores.push({
				id: participantID,
				...this.state.zScores[participantID],
			});
		}
		zScores.sort((a,b) => b.mean - a.mean);

		let categorizedRanks = {};
		for(let i=0; i<zScores.length; i++){
			let categoryID = this.state.separateCategories ? zScores[i].categoryID : 'All categories';
			if(!categorizedRanks[categoryID]){
				categorizedRanks[categoryID] = [];
			}

			let newRankGroup = false;
			let categoryCount = categorizedRanks[categoryID].length;

			if(categoryCount === 0){
				newRankGroup = true;
			}else{
				let lastRankGroupInCat = categorizedRanks[categoryID][categoryCount-1];
				if(lastRankGroupInCat && lastRankGroupInCat.length > 0){
					let lastScoreInRankGroup = lastRankGroupInCat[lastRankGroupInCat.length-1];
					newRankGroup = this.round(zScores[i].mean) !== this.round(lastScoreInRankGroup.mean);
				}
			}
			if(newRankGroup){
				categorizedRanks[categoryID].push([]);
			}
			categorizedRanks[categoryID][categorizedRanks[categoryID].length-1].push(zScores[i]);
		}

		return categorizedRanks;
	}

	setPrecision(value){
		if(value == 0){
			let newDecimals = countDecimals(this.state.roundingStep)+1;
			value = this.state.precision - 10**(-newDecimals);
			value = value.toFixed(newDecimals);
		}
		if(value > 0){
			this.setState({
				precision: value,
				roundingStep: 10**(-countDecimals(value)),
			});
		}
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;
		let categorizedRanks = this.calcScoresByRounded();

		return (
			<div className="page score-screen">
				<h2>{this.state.name} Scores and Ranks</h2>
				<SectionCard title="Summary">
					<div className="responsive-form">
						<div>Participants</div>
						<div>{Object.keys(this.state.participants).length}</div>

						<div title="Judges assigned per participant">Participant load</div>
						<div><em>μ</em>={this.state.participantLoad[0].toFixed(2)}, <em>σ</em>={this.state.participantLoad[1].toFixed(2)}</div>

						<div>Judges</div>
						<div>{Object.keys(this.state.judges).length}</div>

						<div title="Participants assigned per judge">Judging load</div>
						<div><em>μ</em>={this.state.judgingLoad[0].toFixed(2)}, <em>σ</em>={this.state.judgingLoad[1].toFixed(2)}</div>

						<div title="">Judging status</div>
						<div>
							<ProgressBar progress={this.state.totalScoresCompleted/this.state.totalScoresNeeded}>
								{this.state.totalScoresCompleted} of {this.state.totalScoresNeeded} = {(this.state.totalScoresCompleted/this.state.totalScoresNeeded*100).toFixed(0)}%
							</ProgressBar>
						</div>
					</div>

					<details>
						<summary>Judge breakdown</summary>
						<div className="responsive-form" style={{marginLeft:'2.5em'}}>
							{
								Object.keys(this.state.judges).map((judgeID) => {
									let judge = this.state.judges[judgeID];

									let progress;
									if (judge.scoresNeeded > 0){
										progress = <ProgressBar progress={judge.scoresCompleted/judge.scoresNeeded}>
												{judge.scoresCompleted} of {judge.scoresNeeded} = {(judge.scoresCompleted/judge.scoresNeeded*100).toFixed(0)}%
											</ProgressBar>;
									}else{
										progress = <span>no assignments</span>
									}
									return [<div key="name">{judge.name}</div>, <div key="progress">{progress}</div>]
								})
							}
						</div>
					</details>

					<details>
						<summary>Participant breakdown</summary>
						<div className="responsive-form" style={{marginLeft:'2.5em'}}>
							{
								Object.keys(this.state.participants).map((participantID) => {
									let participant = this.state.participants[participantID];
									let status = this.participantStatuses.get(participantID);

									let progress;
									let waitingList = '';

									if(status){
										for(let judge of status['waitingFor']){
											waitingList += '• ' + judge.name + '\n';
										}
										if(waitingList !== ''){
											waitingList = 'Missing scores from these judges:\n\t\n' + waitingList;
										}
										progress = <ProgressBar progress={status.completed/status.total}>
												{status.completed} of {status.total} = {(status.completed/status.total*100).toFixed(0)}%
											</ProgressBar>
									}else{
										progress = 'no assignments';
									}


									return [
										<div key="name">{participant.name}</div>,
										<div key="progress" title={waitingList}>{progress}</div>
									]
								})
							}
						</div>
					</details>

				</SectionCard>

				<SectionCard title="Actions">
					<button
						className="btn btn-primary"
						onClick={()=>this.refreshRankings()}
					>🖩 Calculate rankings</button>
					<button
						className="btn btn-primary"
						onClick={()=>this.downloadScores()}
					>↧ Download raw scores</button>
				</SectionCard>

				<SectionCard title={'Rankings' + (this.state.zScoresTimestamp ? (' as of ' + this.state.zScoresTimestamp.toDate()) : '')}>
					{
						(Object.keys(categorizedRanks).length > 0) ? (
							<div className="rankings-container">
								<div className="ranking">
									<div />
									<input type="text" placeholder="🔍 Filter..." onChange={(e)=>this.applyNameFilter(e.target.value)} />
									<div>Round to: <input type="number" onChange={(e)=>this.setPrecision(e.target.value)} value={this.state.precision} step={this.state.roundingStep}/></div>
								</div>
								{
									Object.keys(this.state.categoryIDs).length > 1 ? <div className="ranking">
										<div />
										<label>
											<input type="checkbox" checked={this.state.separateCategories} onChange={(e)=>this.setState({separateCategories: !this.state.separateCategories})} style={{display: 'inline'}}/>
											Separate categories
										</label>
									</div> : null
								}
								<hr/>
								{
									Object.keys(categorizedRanks).map((categoryID) => {
										let rankedScores = categorizedRanks[categoryID];

										return <React.Fragment key={categoryID}>
											{
												this.state.separateCategories ? (<h4 style={{marginTop: '0.5em'}}>Category: {this.state.categories[categoryID].name}</h4>) : null
											}
											{
												rankedScores.map((participants, groupIdx) => {
												return <div key={groupIdx} className="rank-group">
													{
														participants.map(participant => {
															let place = rankedScores.slice(0, groupIdx).reduce((a,b) => a+b.length, 1);

															let filtered = this.state.nameFilter;
															filtered = filtered && participant.name.toLowerCase().indexOf(this.state.nameFilter) == -1;
															filtered = filtered && participant.projectTitle.toLowerCase().indexOf(this.state.nameFilter) == -1;

															let tied = participants.length > 1;

															return <div key={participant.id} className={'ranking' + (tied?' tied':'') + (filtered?' filtered':'')}>
																<div title={tied ? `This participant is TIED for #${place}`:''}>{(place)}</div>
																<div>
																	<details>
																		<summary>{participant.name} {participant.projectTitle ? <span>({participant.projectTitle})</span>: null}</summary>
																		<p>{participant.details}</p>
																	</details>
																</div>
																<div title={participant.mean}><code>{this.round(participant.mean)}…</code></div>
															</div>
														})
													}
													</div>
												})
											}
										</React.Fragment>
									})
								}
							</div>
						):<div className="btn btn-primary" onClick={()=>this.refreshRankings()}>Calculate ranks now</div>
					}
				</SectionCard>
			</div>
		);
	}

	applyNameFilter(search){
		this.setState({nameFilter: search.toLowerCase()});
	}

	getJudgingStatus(){
		Model.getJudgingStatus(this.groupID, this.eventID).then(judgingStatus => {
			this.setState({judgingStatus: judgingStatus});
		});
	}

	refreshRankings(){
		this.setState({loading:true});
		Model.calculateScores(this.groupID, this.eventID).then(()=>{
			window.location.reload();
		}).catch((err) => {
			console.error(err);
		});
	}

	downloadScores(){
		let records = [];
		let fields = new Set();

		for(let judgeID in this.state.judges){
			let judge = this.state.judges[judgeID];

			for(let participantID in judge.assignments){
				let participant = judge.assignments[participantID];

				if(participantID in this.state.zScores && judgeID in this.state.zScores[participantID].byJudge){
					let record = {
						'Judge name': judge.name,
						'Judge ID': judgeID,
						'Participant name': participant.name,
						'Participant ID': participantID,
						'Category': this.state.categories[participant.categoryID].name,
						'Z-score': this.state.zScores[participantID].byJudge[judgeID],
						'Comments': participant.comments,
					};

					for(let rubricItem in participant.scores){
						let fieldName = 'rubric-' + rubricItem;
						record[fieldName] = participant.scores[rubricItem];
						fields.add(fieldName);
					}
					records.push(record);
				}
			}
		}

		if(records.length > 0){
			for(let field of fields){
				if(!records[0][field]){
					records[0][field] = null;
				}
			}
		}

		let csvData = Papa.unparse(records);

		const file = new Blob([csvData], {type: 'text/plain'});

		const element = document.createElement('a');
		element.style.display = 'none';
		element.href = URL.createObjectURL(file);
		element.download = this.state.name + '-' + (+new Date()) + '-raw.csv';
		document.body.appendChild(element);
		element.click();

	}

	round(num) {
		let multiplier = 1/this.state.precision;
		let rounded = (num*multiplier).toFixed()/multiplier;
		return rounded.toFixed(countDecimals(this.state.precision));
	}
}
