import React, { Component } from 'react';

import ModalDialog from '../components/modalDialog';
export class SavingDialog extends Component {
	render(){
		let reloadable = this.props.reloadable;
		if(reloadable === undefined){
			reloadable = false;
		}
		return <ModalDialog>
				<LoadingScreen text="Saving" timeoutPeriod={this.props.timeoutPeriod} reloadable={reloadable} onDismiss={this.props.onDismiss}/>
			</ModalDialog>
	}
}

export default class LoadingScreen extends Component {
	constructor(){
		super();
		this.state = {
			timedOut: false
		};
	}

	componentDidMount(){
		this.timeout = window.setTimeout(()=>this.onTimeout(), this.props.timeoutPeriod || 10000);
	}

	onTimeout(){
		this.setState({timedOut:true});
	}

	componentWillUnmount(){
		if(this.timeout){
			window.clearTimeout(this.timeout);
		}
	}

	render() {
		let text = this.props.text || 'Loading';
		let reloadable = this.props.reloadable || (this.props.reloadable === undefined);

		return (
			<div className="page">
				<h2 className="loading">
					<span role="img" aria-label="">⏳</span> {text}...
				</h2>
				{
					this.state.timedOut ? (
						<div style={{textAlign:'center'}}>
							<p>This is taking longer than expected.<br/>Please check your network connection.</p>
							{
								reloadable ? (
									<p>You may also try <button className="btn btn-link btn-sm" onClick={()=>window.location.reload()}>↺ reloading</button> this page.</p>
								) : null
							}{
								this.props.onDismiss ? (
									<button className="btn btn-link" onClick={()=>this.props.onDismiss()}>Hide</button>
								):null
							}
						</div>
					):null
				}
			</div>
		);
	}
}