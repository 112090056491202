const configs = new Map();
configs.set('production', {
	apiKey: "AIzaSyB8l0B-yN5qfFK1eQLtxvKJojgPkpB_CKs",
	authDomain: "judge-zed.firebaseapp.com",
	databaseURL: "https://judge-zed.firebaseio.com",
	projectId: "judge-zed",
	storageBucket: "judge-zed.appspot.com",
	messagingSenderId: "686591256089",
});
configs.set('staging', {
	apiKey: "AIzaSyCM6Cvg8EbcifEmvTsNExV6dB7LZPEXwtA",
	authDomain: "judge-zed-staging.firebaseapp.com",
	databaseURL: "https://judge-zed-staging.firebaseio.com",
	projectId: "judge-zed-staging",
	storageBucket: "judge-zed-staging.appspot.com",
	messagingSenderId: "980799755897"
});
configs.set('dev', {
	apiKey: "AIzaSyCM6Cvg8EbcifEmvTsNExV6dB7LZPEXwtA",
	authDomain: "judge-zed-staging.firebaseapp.com",
	databaseURL: "https://judge-zed-staging.firebaseio.com",
	projectId: "judge-zed-staging",
	storageBucket: "judge-zed-staging.appspot.com",
	messagingSenderId: "980799755897"
});

// Add a "name" property to each config
configs.forEach((v,k) => { v.name = k; });

class Environment {
	constructor(){
		this.host = window.location.hostname;
		if(this.host === 'judgezed.com'){
			this.config = configs.get('production');
			this.baseURL = 'https://' + this.host;
		}else if(this.host === 'localhost'){
			this.config = configs.get('dev');
			this.baseURL = 'http://' + this.host + ':3000';
			console.log('** DEVELOPMENT MODE **');
		}else{
			this.config = configs.get('staging');
			this.baseURL = 'http://' + this.host + ':5000';
			console.log('** STAGING SERVER **');
		}

		this.name = this.config.name;
		this.production = (this.name === 'production');
	}
}

export default new Environment();
