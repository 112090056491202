import React, { Component } from 'react';

import LoadingScreen from './loadingScreen';
import Model from '../model';

export default class JudgingScreen extends Component {
	constructor(){
		super();
		this.state = {
			'loading': true,
		};
	}

	componentDidMount(){
		this.groupID = this.props.match.params.groupID;
		this.eventID = this.props.match.params.eventID;
		this.judgeID = this.props.match.params.judgeID;

		Model.loginJudge(this.groupID, this.eventID, this.judgeID)
			.then(() => {
				console.log('Logged in!');
				this.props.history.push(`/judging/${this.groupID}/${this.eventID}/${this.judgeID}`);
			});
	}

	render() {
		return <LoadingScreen />;
	}
}
