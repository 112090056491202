import React, { Component } from 'react';

import QueryString from 'query-string';
import * as Papa from 'papaparse';
import QrReader from 'react-qr-scanner';

import LoadingScreen, {SavingDialog} from './loadingScreen';
import ModalDialog from '../components/modalDialog';
import RadioGroup from '../components/radioGroup';
import SectionCard from '../components/sectionCard';
import BigOlButton from '../components/bigOlButton';
import CopyToClipboardButton from '../components/copyToClipboardButton';

import Model from '../model';

import '../css/event.css';

export default class EventScreen extends Component {

	constructor(){
		super();
		this.state = {
			loading: true,
			saving: false,
			inviteLinkCopyStatus: null,
			emailSending: false,
			detailView: 'details',
			sendZScores: false,
			sendComments: true,
		};

		this.bodyRef = React.createRef();
	}

	componentDidUpdate(prevProps){
		if(this.props.match.params.eventID !== this.eventID){
			this.fetchData();
		}
	}

	componentDidMount(){
		let queryValues = QueryString.parse(this.props.location.search);
		if(queryValues.edit){
			this.setState({editMode: true});
		}else{
			this.setState({editMode: false});
		}
		this.fetchData();
	}

	fetchData(){
		this.setState({loading:true});
		this.groupID = this.props.match.params.groupID;
		this.eventID = this.props.match.params.eventID;

		if(this.eventID && this.eventID !== 'new'){
			Model.getEvent(this.groupID, this.eventID, true, true, true).then(event => {
				for(let participantID in event.participants){
					event.participants[participantID].id = participantID;
				}
				if(!event.participants) event.participants = {};
				if(!event.judges) event.judges = {};

				this.judgingEnabled = event.judgingEnabled;

				this.setState({
					loading: false,
					personInfo: null,
					...event,
				});
			}).catch((exc) => {
				this.props.onError(exc);
			});
		}else{
			this.setState({
				loading: false,
				personInfo: null,
				participants: {},
				judges: {},
				categories: {},
				editMode: true
			});
		}
	}

	render() {
		if(this.state.loading){
			return <LoadingScreen />;
		}

		let judgeCounts = new Map();
		let participantCounts = new Map();

		let totalAssignmentCount = 0;
		for(let judgeID in this.state.judges){
			let judge = this.state.judges[judgeID];
			if(!judge.deleted){
				for(let participantID in judge.assignments){
					let participant = this.state.participants[participantID];
					if(participant && !participant.deleted){
						judgeCounts.set(judgeID, (judgeCounts.get(judgeID)||0) + 1);
						participantCounts.set(participantID, (participantCounts.get(participantID)||0) + 1);
						totalAssignmentCount++;
					}
				}
			}
		}

		let controls = new Map();
		if(this.state.editMode){
			controls.set('name', <input type="text" className="form-control" value={this.state.name} onChange={(e)=>this.setState({'name': e.target.value})} />);
			controls.set('judgingEnabled', <RadioGroup
				className='enableDisable'
				choices={['Enabled', 'Disabled']}
				value={this.state.judgingEnabled ? 'Enabled' : 'Disabled'}
				onChange={(val) => this.setState({judgingEnabled: val === 'Enabled'})}
			/>);
			controls.set('commentsEnabled', <RadioGroup
				className='enableDisable'
				choices={['Enabled', 'Disabled']}
				value={this.state.commentsEnabled ? 'Enabled' : 'Disabled'}
				onChange={(val) => this.setState({commentsEnabled: val === 'Enabled'})}
			/>);
			controls.set('publicLandingEnabled', <RadioGroup
				className='enableDisable'
				choices={['Enabled', 'Disabled']}
				value={this.state.publicLandingEnabled ? 'Enabled' : 'Disabled'}
				onChange={(val) => this.setState({publicLandingEnabled: val === 'Enabled'})}
			/>);
			controls.set('description', <textarea
				className="form-control"
				value={this.state.description || ''}
				onChange={(e)=>this.setState({'description': e.target.value})}
			/>);
			controls.set('categories', <CategoriesForm categories={this.state.categories}
				onCategoryUpdated={(idx, category)=>this.updateCategory(idx, category)}
				addCategory={()=>this.addCategory()}
				addRubricRow={(categoryID)=>this.addRubricRow(categoryID)}
				updateRubric={(categoryID, rubric)=>this.updateRubric(categoryID, rubric)}
			/>);
			//<RubricForm rubric={this.state.rubric} onChange={(rubric)=>this.updateRubric(rubric)} deleteRow={(row)=>this.deleteRubricRow(row)}/>
		}else{
			controls.set('name', <span>{this.state.name}</span>);
			controls.set('judgingEnabled', <span>{this.state.judgingEnabled ? '✔ Enabled' : '✘ Disabled'}</span>);
			controls.set('commentsEnabled', <span>{this.state.commentsEnabled ? '✔ Enabled' : '✘ Disabled'}</span>);
			controls.set('publicLandingEnabled', <span>{this.state.publicLandingEnabled ? '✔ Enabled' : '✘ Disabled'}</span>);
			controls.set('description', <span>{this.state.description}</span>);
			controls.set('categories', <CategoryDisplay categories={this.state.categories} onCategoryUpdated={(idx, category)=>this.updateCategory(idx, category)} />);
		}

		this.saveFunc = this.cancelEdits;

		if(this.state.detailView === 'details'){
			this.saveFunc = this.saveDetails;
		}else if(this.state.detailView === 'assignments'){
			this.saveFunc = this.saveAssignments;
		}else if(this.state.detailView === 'participants'){
			this.saveFunc = this.saveParticipants;
		}else if(this.state.detailView === 'judges'){
			this.saveFunc = this.saveJudges;
		}else if(this.state.detailView === 'categories'){
			this.saveFunc = this.saveCategories;
		}

		let saveCancelButtons = null;
		let editButton = null;

		if(this.state.editMode){
			saveCancelButtons = <div style={{textAlign:'right'}}>
				<button className="btn btn-link" onClick={()=>this.cancelEdits()}>Cancel</button>
				<button className="btn btn-primary" onClick={()=>this.saveFunc()}>Save</button>
			</div>
		}else{
			editButton = <button className="btn btn-primary" onClick={()=>this.setState({editMode:true})}>✏️ Edit</button>;
		}

		return (
			<div className="page event-screen">
				{ this.state.saving ? <SavingDialog onDismiss={()=>this.setState({saving:false})}/> : null }

				<div className={this.state.saving?'disabled':''}>

					<h2>{this.state.name}</h2>

					<div className={'big-ol-button-container' + (this.state.editMode?' disabled':'')}>
						<BigOlButton top="Judging" bottom={this.judgingEnabled ? 'Enabled' : 'Disabled'} onClick={()=>this.gotoView('details')}>
							{this.judgingEnabled ? '✅' : '🚫'}
						</BigOlButton>

						<BigOlButton top="Categories" bottom={Object.keys(this.state.categories).length} onClick={()=>this.gotoView('categories')}>
							📝
						</BigOlButton>

						<BigOlButton top="Judges" bottom={Object.keys(this.state.judges).length}  onClick={()=>this.gotoView('judges')}>
							👩🏽‍⚖️
						</BigOlButton>

						<BigOlButton top="Participants" bottom={Object.keys(this.state.participants).length} onClick={()=>this.gotoView('participants')}>
							👩🏽‍🔬
						</BigOlButton>

						<BigOlButton top="Assignments" bottom={totalAssignmentCount} onClick={()=>this.gotoAssignmentsPage()}>
							👩🏽‍⚖️⇔👩🏽‍🔬
						</BigOlButton>

						<BigOlButton top="Rankings" bottom='&nbsp;' onClick={()=>this.gotoScores()}>
							🥇
						</BigOlButton>
					</div>

					<hr ref={this.bodyRef}/>

					{
						(this.state.detailView === 'details') ? (
							<React.Fragment>
								<SectionCard title="Basic information" buttons={editButton}>
									<div className="responsive-form tight">
										<label>Name</label>
										<div>{controls.get('name')}</div>

										<label>Description</label>
										<div>{controls.get('description')}</div>

										<label>Judging</label>
										<div>{controls.get('judgingEnabled')}</div>

										<label>Comments</label>
										<div>{controls.get('commentsEnabled')}</div>

										<label>Public landing page</label>
										<div>{controls.get('publicLandingEnabled')}<a style={{display: 'block'}} href={`/groups/${this.groupID}/events/${this.eventID}`} target="_blank" title="Public landing page">Open landing page</a></div>

									</div>
									{saveCancelButtons}
								</SectionCard>

								<SectionCard title="Mass email">
									<details>
										<summary>Email links to all judges...</summary>
										{
											this.judgingEnabled ? (
												<button className={'btn btn-primary' + (this.state.emailSending?' disabled':'')} onClick={()=>this.sendInvites()}>Email invites to all {Object.keys(this.state.judges).length} judges</button>
											):(<em>You must <strong>enable</strong> judging before you can perform this action</em>)
										}
									</details>
									<br/>
									<details>
										<summary>Email comments/scores all participants...</summary>
										{
											(!this.judgingEnabled) ? (
												<div>
													<div className="responsive-form table tight">
														<label>Option</label>
														<label>Setting</label>

														<label>Include Z-scores</label>
														<label><input type="checkbox" onChange={(e)=>this.setState({'sendZScores':e.target.checked})} checked={this.state.sendZScores} /></label>

														<label>Include comments</label>
														<label><input type="checkbox" onChange={(e)=>this.setState({'sendComments':e.target.checked})} checked={this.state.sendComments} /></label>
													</div>
													<button className={'btn btn-primary' + (this.state.emailSending?' disabled':'')} onClick={()=>this.sendScoreSheets()} disabled={!(this.state.sendComments||this.state.sendZScores)}>
														{
															!(this.state.sendComments||this.state.sendZScores) ? (
																<span>No options selected</span>
															):(
																<span>Send
																	{this.state.sendZScores ? ' Z-scores ':''}
																	{this.state.sendZScores && this.state.sendComments ? 'and':''}
																	{this.state.sendComments ? ' comments ':''}
																	to all {Object.keys(this.state.participants).length} participants
																</span>
															)
														}
													</button>
												</div>
											):(<em>You must <strong>disable</strong> judging before you can perform this action</em>)
										}
									</details>
								</SectionCard>

							</React.Fragment>
						):null
					}

					{
						(this.state.detailView === 'categories') ? (
							<SectionCard title="Categories"
								buttons={editButton}
							>
								{controls.get('categories')}
								{saveCancelButtons}
							</SectionCard>
						):null
					}

					{
						(this.state.detailView === 'judges') ? (
							<SectionCard title="Judges"
								buttons={editButton}
							>
								<JudgeList
									groupID={this.groupID} eventID={this.eventID} people={this.state.judges}
									onError={this.props.onError}
									onMessage={this.props.onMessage}
									editMode={this.state.editMode}
									onPersonUpdated={(person)=>this.onJudgeUpdated(person)}
								/>
								{
									this.state.editMode ? (
										<div style={{marginTop: '1em'}} >
											<button className="btn btn-primary btn-sm" onClick={()=>this.addJudge()}>+1 Add judge</button>
											<label className="btn btn-primary btn-sm" title="Add judges via CSV file">
												<input type="file" onChange={(e)=>this.batchJudges(e.target.files[0])} accept=".csv,.tab"/>
												↥ Upload CSV
											</label>
											<button className="btn btn-secondary btn-sm" onClick={()=>this.downloadJudgeCSVTemplate()}>
												↧ Download CSV Template
											</button>
										</div>
									):null
								}
								{saveCancelButtons}
							</SectionCard>
						):null
					}

					{
						(this.state.detailView === 'participants') ? (
							<SectionCard title="Participants"
								buttons={editButton}
							>
								<ParticipantList
									groupID={this.groupID} eventID={this.eventID} people={this.state.participants}
									onError={this.props.onError}
									onMessage={this.props.onMessage}
									editMode={this.state.editMode}
									onPersonUpdated={(person)=>this.onParticipantUpdated(person)}
									categories={this.state.categories}
								/>
								{
									this.state.editMode ? (
										<div style={{marginTop: '1em'}}>
											<button className="btn btn-primary btn-sm" onClick={()=>this.addParticipant()}>+1 Add participant</button>
											<label className="btn btn-primary btn-sm" title="Add participants via CSV file">
												<input type="file" onChange={(e)=>this.batchParticipants(e.target.files[0])} accept=".csv,.tab"/>
												↥ Upload CSV
											</label>
											<button className="btn btn-secondary btn-sm" onClick={()=>this.downloadParticipantCSVTemplate()}>
												↧ Download CSV Template
											</button>
										</div>
									):null
								}
							{saveCancelButtons}
							</SectionCard>
						):null
					}

					{/*buttons*/}
				</div>
			</div>

		);
	}

	gotoView(viewName){
		this.setState({detailView:viewName}, () => {
			this.bodyRef.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
		});
	}

	addCategory(){
		let id = ' New-'+(Object.keys(this.state.categories).length);
		let template = {
			name: 'New Category',
			rubric: [],
			expanded: true
		}


		this.setState((state) => {
			let categories = {...state.categories};
			categories[id] = template;
			return {categories: categories};
		});
	}

	updateCategory(categoryID, category){
		this.setState((state) => {
			let categories = {...state.categories};
			categories[categoryID] = {...categories[categoryID], ...category};

			return {categories: categories};
		});
	}

	updateRubric(categoryID, rubric){
		this.setState((state) => {
			let categories = {...state.categories};
			categories[categoryID].rubric = rubric;
			return {categories: categories};
		});
	}

	addRubricRow(categoryID){
		this.setState((state) => {
			let categories = {...state.categories};
			categories[categoryID].rubric = [...categories[categoryID].rubric, {min:1, max:10}];
			return {categories: categories};
		});
	}

	deleteRubricRow(categoryID, rowIdx){
		this.setState((state) => {
			let categories = {...state.categories};
			let rubric = [...categories[categoryID].rubric];
			rubric[rowIdx].deleted = true;

			return {categories: categories};
		});
	}

	saveDetails(){
		let event = {
			name: this.state.name,
			description: this.state.description,
			judgingEnabled: this.state.judgingEnabled,
			commentsEnabled: this.state.commentsEnabled,
			publicLandingEnabled: this.state.publicLandingEnabled,
		};
		this.setState({saving:true});

		Model.updateEventDetails(this.groupID, this.eventID, event).then(() => {
			this.judgingEnabled = this.state.judgingEnabled;
			this.setState({saving:false, editMode: false});
		}).catch((exc) => {
			this.props.onError(exc, 'Saving failed. Please check your input');
			this.setState({saving:false});
		});
	}

	saveRubric(){
		let event = {
			rubric: this.state.rubric.filter((item) => !item.deleted),
		};
		this.setState({saving:true});

		Model.updateEventDetails(this.groupID, this.eventID, event).then(() => {
			this.setState({saving:false, editMode: false});
		}).catch((exc) => {
			this.props.onError(exc, 'Saving failed. Please check your input');
			this.setState({saving:false});
		});
	}

	cancelEdits(){
		if(this.eventID === 'new'){
			this.props.history.goBack();
		}else{
			window.location.reload();
		}
	}

	delete(){
		if(window.confirm('Are you sure you want to DELETE "' + this.state.name + '"?\n\nThis action cannot be undone.')){
			this.setState({'loading': true});
			Model.deleteEvent(this.groupID, this.eventID).then(() => {
				this.props.onMessage('"' + this.state.name + '" was deleted forever');
				this.props.history.goBack();
			});
		}
	}

	saveCategories(){
		this.setState({saving: true});
		Model.saveCategories(this.groupID, this.eventID, this.state.categories).then(() => {
			window.location.reload();
		});
	}

	saveJudges(){
		this.setState({saving: true});
		Model.saveJudges(this.groupID, this.eventID, this.state.judges).then(() => {
			window.location.reload();
		});
	}

	saveParticipants(){
		this.setState({saving: true});
		Model.saveParticipants(this.groupID, this.eventID, this.state.participants).then(() => {
			window.location.reload();
		});
	}

	saveParticipant(){
		let participants = this.state.participants;
		let person = {id:' New-'+(Object.keys(participants).length), ...this.state.personInfo};
		participants[person.id] = person;

		let judges = this.state.judges;
		for(let judgeID in judges){
			let judge = judges[judgeID];
			for(let assignmentID in judge.assignments){
				if(assignmentID === person.id){
					judge.assignments[assignmentID].name = person.name;
					judge.assignments[assignmentID].details = person.details;
				}
			}
		}

		this.setState({
			participants: participants,
			judges: judges,
			personInfo: null,
		});
	}

	addParticipant(){
		let participants = this.state.participants;
		let person = {
			id: ' New-'+(Object.keys(this.state.participants).length),
			name: 'NEW PARTICIPANT',
		};
		participants[person.id] = person;

		this.setState({participants: participants});
	}

	addJudge(){
		let judges = this.state.judges;
		let person = {
			id: ' New-'+(Object.keys(this.state.judges).length),
			name: 'NEW JUDGE',
		};
		judges[person.id] = person;

		this.setState({judges: judges});
	}

	gotoScores(){
		this.props.history.push('/groups/' + this.groupID + '/events/' + this.eventID + '/score');
	}

	gotoAssignmentsPage(){
		if(Object.keys(this.state.judges).length === 0 || Object.keys(this.state.participants).length === 0){
			alert("You'll need to add judges and participants first :)");
		}else{
			this.props.history.push(`/groups/${this.groupID}/events/${this.eventID}/assignments`);
		}
	}

	downloadParticipantCSVTemplate(){
		let records = [];
		for(let name of ['One', 'Two', 'Three']){
			records.push({'name': `Participant ${name}`, 'email': `Participant-${name}@email-address.com`, 'projectTitle': `Participant ${name} project title`, 'details': `Extra info about Participant ${name}`, 'url': `https://link-to-participant-${name}-submission`, 'category': ''})
		}
		let csvData = Papa.unparse(records);

		const file = new Blob([csvData], {type: 'text/plain'});

		const element = document.createElement('a');
		element.style.display = 'none';
		element.href = URL.createObjectURL(file);
		element.download = 'participants-template.csv';
		document.body.appendChild(element);
		element.click();
		element.remove();
	}

	batchParticipants(file){
		let reader = new FileReader();

		reader.onload = (event) => {
			let participants = this.state.participants;
			let newbies = parseCSV(event.target.result);

			let categoryLookup = {};
			for(let categoryID in this.state.categories){
				categoryLookup[this.state.categories[categoryID].name] = categoryID;
			}

			for(let newbie of newbies){
				let person = {
					id:' New-'+(Object.keys(participants).length),
					categoryID: (newbie.category in categoryLookup ? categoryLookup[newbie.category] : ''),
					...newbie
				};
				participants[person.id] = person;
			}

			this.setState({
				participants: participants
			});
		};
		reader.readAsText(file);
	}

	downloadJudgeCSVTemplate(){
		let records = [
			{'name': 'Judge One', 'email': 'judge-one@email-address.com', 'details': 'Extra info about Judge One'},
			{'name': 'Judge Two', 'email': 'judge-two@email-address.com', 'details': 'Extra info about Judge Two'},
			{'name': 'Judge Three', 'email': 'judge-three@email-address.com', 'details': 'Extra info about Judge Three'},
		];
		let csvData = Papa.unparse(records);

		const file = new Blob([csvData], {type: 'text/plain'});

		const element = document.createElement('a');
		element.style.display = 'none';
		element.href = URL.createObjectURL(file);
		element.download = 'judges-template.csv';
		document.body.appendChild(element);
		element.click();
		element.remove();
	}

	batchJudges(file){
		let reader = new FileReader();

		reader.onload = (event) => {
			let judges = this.state.judges;
			let newbies = parseCSV(event.target.result);
			for(let newbie of newbies){
				let person = {
					id:' New-'+(Object.keys(judges).length),
					...newbie
				};
				judges[person.id] = person;
			}

			this.setState({
				judges: judges,
			});

		};
		reader.readAsText(file);
	}

	temporarilySetCopyInviteStatus(status){
		this.setState({'inviteLinkCopyStatus': status});
		setTimeout(
			()=>{this.setState({'inviteLinkCopyStatus': null})},
			3000
		);
	}

	copyInviteLinkClipboard(){
		let port = window.location.port ? `:${window.location.port}` : '';
		navigator.clipboard.writeText(`${window.location.protocol}//${window.location.hostname}${port}/invite/${this.groupID}/${this.eventID}/${this.state.personInfo.id}`)
			.then( () => {
				this.temporarilySetCopyInviteStatus(true);
			}, () => {
				this.temporarilySetCopyInviteStatus(false);
			});
	}

	sendInvites(){
		this.setState({emailSending: true});
		Model.sendInvites(this.groupID, this.eventID)
			.then( () => {
				this.setState({emailSending: false});
				this.props.onMessage('Emails have been queued');
			}).catch( (error) => {
				this.setState({emailSending: false});
				this.props.onError(error, 'One or more emails likely failed to send.');
			});
	}

	sendScoreSheets(){
		this.setState({emailSending: true});
		Model.sendAllScoreSheets(this.groupID, this.eventID, this.state.sendZScores, this.state.sendComments)
			.then( (errors) => {
				errors = errors.filter(e => e);
				if(errors.length > 0){
					let error = {
						'errors': errors
					};
					this.props.onError(error, 'One or more emails likely failed to send.');
				}else{
					this.props.onMessage('Emails have been queued');
				}

				this.setState({emailSending: false});
			}).catch( (error) => {
				this.setState({emailSending: false});
				this.props.onError(error, 'One or more emails likely failed to send.');
			});
	}

	onJudgeUpdated(judge){
		this.setState((state) => {
			let judges = {...state.judges};
			judges[judge.id] = judge;
			return { judges: judges };
		});
	}

	onParticipantUpdated(participant){
		this.setState((state) => {
			let participants = {...state.participants};
			participants[participant.id] = participant;

			return {
				participants: participants,
			};
		});
	}
}

function parseCSV(text){
	return Papa.parse(
		text,
		{
			header: true,
			skipEmptyLines: true,
		}
	).data;
}

class CategoryDisplay extends Component {
	render() {
		return <div className="table tight">
			{
				Object.keys(this.props.categories).map((categoryID, i)=>{
					let categoryItem = this.props.categories[categoryID];
					return <React.Fragment key={categoryID}>
						<div onClick={()=>this.props.onCategoryUpdated(categoryID, {expanded: !categoryItem.expanded})}>
							{categoryItem.name}
						</div>
						{
							categoryItem.expanded ? (
								<div style={{paddingLeft: '2em'}} className={'responsive-form tight' + (categoryItem.deleted ? ' deleted':'')}>
									<label>Name</label>
									<span>{categoryItem.name}</span>
									<label>Rubric</label>

									<RubricDisplay rubric={categoryItem.rubric} />
								</div>
							):null
						}
					</React.Fragment>
				})
			}
		</div>
	}
}
class RubricDisplay extends Component {
	constructor(props){
		super(props);
		this.state = {
			rubric: props.rubric || [],
		};
	}

	render(){
		if(!this.state.rubric || this.state.rubric.length === 0){
			return <div>No rubric items added yet</div>
		}else{
			return <div className="responsive-form table tight">
				<div>Item name</div>
				<div>Score range</div>
				{
					this.state.rubric.map((rubricItem, i)=>{
						return rubricItem.deleted ? (
							<React.Fragment key={i}/>
						):(
							<React.Fragment key={i}>
								<div>{rubricItem.name}</div>
								<div>
									{rubricItem.min} - {rubricItem.max}
									{rubricItem.allowNA ? <span>&nbsp;or NA</span> : null}
								</div>
							</React.Fragment>
						)
					})
				}
			</div>
		}
	}
}

class CategoriesForm extends Component {
	render() {
		return <div className="table tight">
			{
				Object.keys(this.props.categories).map((categoryID, i)=>{
					let categoryItem = this.props.categories[categoryID];
					return <React.Fragment key={categoryID}>
						<div onClick={()=>this.props.onCategoryUpdated(categoryID, {expanded: !categoryItem.expanded})}>
							{categoryItem.name}
						</div>
						{
							categoryItem.expanded ? (
								<div style={{paddingLeft: '2em'}} className={'responsive-form tight' + (categoryItem.deleted ? ' deleted':'')}>
									<label>Name</label>
									<input
										className="form-control"
										type="text"
										defaultValue={categoryItem.name}
										onBlur={(e)=>this.props.onCategoryUpdated(categoryID, {name: e.target.value})}
									/>
									<label>Rubric</label>

									<RubricForm
										rubric={categoryItem.rubric}
										onChange={(rubric)=>this.props.updateRubric(categoryID, rubric)}
										addRow={()=>this.props.addRubricRow(categoryID)}
									/>

									<label>&nbsp;</label>
									<button className="btn btn-primary btn-sm" onClick={()=>this.props.addRubricRow(categoryID)}>+1 Add rubric item</button>

								</div>
							):null
						}
					</React.Fragment>
				})
			}
			<button className="btn btn-primary btn-sm" onClick={()=>this.props.addCategory()}>+1 Add Category</button>
		</div>
	}
}

class RubricForm extends Component {
	constructor(props){
		super(props);
		this.state = {
			rubric: props.rubric,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		if(JSON.stringify(prevProps.rubric) !== JSON.stringify(this.props.rubric)){
			let z = [...this.props.rubric];
			this.setState((state) => {
				return {rubric: z}
			});
		}
	}

	render(){
		return <React.Fragment>
				<div className="rubric-form">
				{
					this.state.rubric.map((rubricItem, i)=>{
						return rubricItem.deleted?(
							<React.Fragment key={i}></React.Fragment>
						):(
							<RubricRow key={i} {...rubricItem}
								onChange={(data)=>this.rowUpdated(i, data)}
								onDelete={()=>this.rowUpdated(i, {deleted: !rubricItem.deleted})}
							/>)
					})
				}
				</div>
		</React.Fragment>
	}

	rowUpdated(row, data){
		let rubric = this.state.rubric;
		rubric[row] = data;

		if(this.props.onChange){
			this.props.onChange(rubric);
		}
	}
}

class RubricRow extends Component {
	constructor(props){
		super(props);
		this.state = {
			name: props.name || '',
			description: props.description || '',
			min: props.min,
			max: props.max,
			allowNA: !!props.allowNA,
			expanded: props.name === undefined,
		};
	};

	render(){
		return [
			<input key='name' className="form-control" type="text" value={this.state.name} onChange={(e)=>this.updateState({name:e.target.value})} placeholder="Item name" title="Item name"/>,
			<input key='min' className="form-control" type="number" value={this.state.min} onChange={(e)=>this.updateState({min:e.target.value})} placeholder="Min" title="Min"/>,
			<input key='max' className="form-control" type="number" value={this.state.max} onChange={(e)=>this.updateState({max:e.target.value})} placeholder="Max" title="Max"/>,
			<label title="Allow NA"><input type="checkbox" key='allowNA' checked={this.state.allowNA} onChange={(e)=>this.updateState({allowNA:e.target.checked})}/>NA</label>,
			<textarea key='details'
				className="form-control"
				placeholder={'Details' + (this.state.name !== '' ? (' for ' + this.state.name ) : '')}
				onChange={(e)=>this.updateState({description:e.target.value})}
				value={this.state.description}
				rows="4"
				title="Details"
			/>,
			<button key='delete' onClick={()=>this.deleteMe()} className="btn btn-danger">Delete {this.state.name ? '"'+this.state.name+'"' : 'item'}</button>
		]
	}

	updateState(newState){
		for(let prop in newState){
			if(prop === 'min' || prop === 'max'){
				newState[prop] = parseInt(newState[prop]);
			}
		}
		if(this.props.onChange){
			this.props.onChange({...this.state, ...newState});
		}
		this.setState(newState);
	}

	deleteMe(){
		if(this.props.onDelete){
			this.props.onDelete();
		}
	}
}

class PeopleList extends Component {
	constructor(props){
		super(props);

		let people = this.props.people;
		if(!Array.isArray(people)){
			// It's a dict
			people = Object.values(people);
		}

		this.state = {
			loading: false,
			expandedUser: null,
			editMode: props.editMode,
			people: people,
			matchedPeople: people,
		};
		this.typeTimer = null;
		this.searchString = '';
	}

	componentDidUpdate(prevProps, prevState, snapshot){
		if (prevState.editMode !== this.props.editMode) {
			this.setState({editMode: this.props.editMode});
		}

		let people = Object.values(this.props.people);
		if(JSON.stringify(prevState.people) !== JSON.stringify(people)){
			this.setState({people: people});
			this.onSearchChanged();
		}
	}

	togglePersonExpansion(person){
		if(this.state.expandedPerson === person){
			this.setState({'expandedPerson':null})
		}else{
			this.setState({'expandedPerson':person})
		}
	}

	resetTypeTimer(searchString){
		window.clearTimeout(this.typeTimer);

		this.searchString = searchString;
		this.typeTimer = window.setTimeout(()=>this.onSearchChanged(), 250);
	}

	onSearchChanged(){
		if(this.searchString.trim() === ""){
			this.setState({
				matchedPeople: this.state.people,
			});
		}else{
			let terms = this.searchString.split(' ');

			let matches = this.state.people.filter(
				person => {
					return terms.reduce((matches, term) => {
						let matchString = (person.name + ' ' + person.details + ' ' + person.email).toLowerCase();
						return (matches && matchString.includes(term.toLowerCase()))
					}, true)
				}
			);

			let expandedPerson = this.state.expandedPerson;
			if(matches.length === 1){
				expandedPerson = matches[0];
			}

			this.setState({
				matchedPeople: matches,
				expandedPerson: expandedPerson
			});
		}
	}
}

class JudgeList extends PeopleList {
	constructor(props){
		super(props);

		this.state = {
			...this.state,
			emailSending: false,
			typedUID: null,
		};
	}

	componentWillMount(){
		navigator.mediaDevices.enumerateDevices().then((devices) => {
			const videoSelect = [];
			devices.forEach((device) => {
				if (device.kind === 'videoinput') {
					videoSelect.push(device)
				}
			})
			return videoSelect
		}).then((cameras) => {
			this.cameras = cameras;
			this.setState({
				cameraID: (cameras.length > 0 ? cameras[cameras.length-1].deviceId : undefined),
			})
		}).catch((error) => {
			this.scanError(error);
		})
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		let port = window.location.port ? `:${window.location.port}` : '';
		let inviteURL = null;
		if(this.state.expandedPerson){
			inviteURL = `${window.location.protocol}//${window.location.hostname}${port}/invite/${this.props.groupID}/${this.props.eventID}/${this.state.expandedPerson.id}`;
		}

		let scannerContent = null;
		if(this.state.scanningForJudge){
			let buttons = <React.Fragment>
				<button className="btn btn-primary" onClick={(e)=>this.pairJudge(this.state.typedUID)}>Manual entry</button>
				<button className="btn btn-secondary" onClick={(e)=>this.setState({scanningForJudge:null})}>Cancel</button>
				<button className="btn btn-danger" disabled={!Boolean(this.state.scanningForJudge.uid)} onClick={(e)=>this.clearJudgeAssociation()}>Un-pair</button>
			</React.Fragment>;

			scannerContent = <ModalDialog
				title='Scan QR code'
				footer={buttons}
			>
				<div style={{textAlign:'center'}}>
					{
						this.state.scanError ? (
							<div style={{
								height: '20em',
								display: 'flex',
								padding: '1em',
								margin: '1em',
								backgroundColor: '#F22', color: '#fff',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
								Your camera does not appear to be working.
							</div>
						) : (
							<React.Fragment>
								{
									this.state.cameraID === 'switching' ? (
										<div style={{
											height: '20em',
											display: 'flex',
											padding: '1em',
											margin: '1em',
											backgroundColor: '#444',
											alignItems: 'center',
											justifyContent: 'center'
										}}>⌛</div>
									) : (
										<QrReader
											onScan={(data)=>this.handleScan(data)}
											onError={(e)=>this.scanError(e)}
											delay={100}
											facingMode="rear"
											style={{width: '100%', height: 'auto', maxHeight: '20em'}}
											constraints={{
												video: {
													deviceId: this.state.cameraID
												}
											}}
										/>
									)
								}
							</React.Fragment>

						)
					}
					<select
						style={{display: this.cameras.length > 1}}
						onChange={e => {
							const value = e.target.value;
							this.setState({ cameraID: 'switching' });
							// a short pause between swapping cameras prevents errors on mobile devices
							setTimeout(() => this.setState({ cameraID: value }), 1000);
						}}
					>
						{
							this.cameras.map((deviceInfo, index) => (
								<React.Fragment key={deviceInfo.deviceId}><option selected={deviceInfo.deviceId === this.state.cameraID} value={deviceInfo.deviceId}>{deviceInfo.label || `Camera ${index+1}`}</option></React.Fragment>
							))
						}
					</select>
					<h2>{this.state.scanningForJudge.name}</h2>
					<input onChange={(e)=>this.setState({typedUID:e.target.value})} />
				</div>
			</ModalDialog>
		}

		return (
			<div>
				<input id="searchBox" type="text" className="form-control" placeholder="Filter" onChange={(e)=>this.resetTypeTimer(e.target.value)} style={{textAlign:'center'}}/>
				{
					this.state.people.length === 0 ? (
						<p>No judges have been added yet</p>
					):null
				}{
					(this.state.people.length > 0 && this.state.matchedPeople.length === 0) ? (
						<p>No judges match your filter</p>
					):null
				}
				<div className="table tight" style={{marginTop: '.5em'}}>
					{
						this.state.matchedPeople.map((person) => {
							return <React.Fragment key={person.id}>
									<div onClick={()=>this.togglePersonExpansion(person)} className={'clickable' + (person.deleted ? ' deleted':'')}>{person.name}</div>
									{
										(this.state.expandedPerson && (this.state.expandedPerson.id === person.id)) ? (
											<React.Fragment>
												<div style={{paddingLeft: '2em'}} className={'responsive-form tight' + (person.deleted ? ' deleted':'')}>
													<label>Name</label>
													<div>
														{
															this.state.editMode ? (
																<input className="form-control" type="text" defaultValue={person.name} onBlur={(e)=>this.props.onPersonUpdated({...person, name: e.target.value})}/>
															):<span>{person.name}</span>
														}
													</div>

													<label>Email</label>
													<div>
														{
															this.state.editMode ? (
																<input className="form-control" type="email" defaultValue={person.email} onBlur={(e)=>this.props.onPersonUpdated({...person, email: e.target.value})}/>
															):<a href={`mailto:${person.email}`}>{person.email}</a>
														}
													</div>

													<label>Details</label>
													<div>
														{
															this.state.editMode ? (
																<textarea className="form-control" defaultValue={person.details} onBlur={(e)=>this.props.onPersonUpdated({...person, details: e.target.value})}/>
															):<span>{person.details}</span>
														}
													</div>

													{
														person.id.startsWith(' New-') ? (null):(
															<React.Fragment>
																<label>Assignments</label>
																<div><a target="_blank" href={`/judging/${this.props.groupID}/${this.props.eventID}/${person.id}`} className="btn btn-sm btn-secondary">View judging forms</a></div>

																<label>Invitation</label>
																<div>
																	<button className="btn btn-primary" onClick={()=>this.doScan(person)}>📱 Pair device</button>
																	<CopyToClipboardButton copyText={inviteURL} className="btn-sm">🗊 Copy link</CopyToClipboardButton>
																	<button className="btn btn-sm btn-secondary" onClick={()=>this.sendInviteLink()} disabled={this.state.emailSending}>
																		{this.state.emailSending ? 'Sending...' : 'Email now'}
																	</button>
																</div>
															</React.Fragment>
														)
													}

													{
														this.state.editMode ? (
															<React.Fragment>
																<label>&nbsp;</label>
																<div>
																	<button className="btn btn-sm btn-danger" onClick={()=>this.props.onPersonUpdated({...person, deleted:!person.deleted})}>
																		{person.deleted ? 'Restore':'Delete'} {person.name}
																	</button>
																</div>
															</React.Fragment>
														):null
													}

												</div>
											</React.Fragment>
										):null
									}

								</React.Fragment>
						})
					}
				</div>

				{scannerContent}
			</div>
		);
	}

	doScan(judge){
		this.setState({
			scanError: false,
			scanningForJudge: judge
		});
	}

	handleScan(data){
		if(!data || !data.text || this.state.scanProcessing){
			return;
		}

		let uid = data.text;
		this.pairJudge(uid)
	}

	pairJudge(uid){
		this.setState({scanProcessing: true});
		Model.approveJudge(this.props.groupID, this.props.eventID, this.state.scanningForJudge.id, uid).then((result)=>{
			this.setState({
				scanningForJudge: null,
				scanProcessing:false,
				matchedPeople: this.state.people,
			});

			this.searchString = '';
			document.getElementById('searchBox').value = '';
			window.setTimeout(()=>document.getElementById('searchBox').focus(), 100);
		});
	}

	scanError(error){
		error = {
			...error,
			toString: ()=>{
				return error.name + ': ' + error.message;
			}
		};
		this.props.onError(error, 'Scanning error');
		this.setState({scanError:true});
	}

	sendInviteLink(){
		if(!window.confirm(`This will send an email to "${this.state.expandedPerson.name}" <${this.state.expandedPerson.email}> - are you sure?`)){
			return;
		}
		this.setState({emailSending: true});
		Model.sendSingleInvite(this.props.groupID, this.props.eventID, this.state.expandedPerson.id).then(() => {
			this.setState({emailSending: false});
			this.props.onMessage('The email has been queued.');
		}).catch((exc) => {
			this.setState({emailSending: false});
			this.props.onError(exc, 'The email likely failed to send.');
		});
	}
}

class ParticipantList extends PeopleList {
	constructor(props){
		super(props);

		this.state = {
			...this.state,
			sendZScores: false,
			sendComments: true,
			emailSending: false,
		};
	}

	render() {
		if(this.state.loading) return <LoadingScreen />;

		return (
			<div>
				<input type="text" className="form-control" placeholder="Filter" onChange={(e)=>this.resetTypeTimer(e.target.value)} style={{textAlign:'center'}}/>
				{
					this.state.people.length === 0 ? (
						<p>No participants have been added yet</p>
					):null
				}{
					(this.state.people.length > 0 && this.state.matchedPeople.length === 0) ? (
						<p>No participants match your filter</p>
					):null
				}
				<div className="table tight" style={{marginTop: '.5em'}}>
					{
						this.state.matchedPeople.map((person) => {
							return <React.Fragment key={person.id}>
									<div onClick={()=>this.togglePersonExpansion(person)} className={'clickable' + (person.deleted ? ' deleted':'')}>{person.name}</div>
									{
										(this.state.expandedPerson && (this.state.expandedPerson.id === person.id)) ? (
											<React.Fragment>
												<div style={{paddingLeft: '2em'}} className={'responsive-form tight' + (person.deleted ? ' deleted':'')}>
													<label>Name</label>
													<div>
														{
															this.state.editMode ? (
																<input className="form-control" type="text" defaultValue={person.name} onBlur={(e)=>this.props.onPersonUpdated({...person, name: e.target.value})}/>
															):<span>{person.name}</span>
														}
													</div>

													<label>Project title</label>
													<div>
														{
															this.state.editMode ? (
																<input className="form-control" type="text" defaultValue={person.projectTitle} onBlur={(e)=>this.props.onPersonUpdated({...person, projectTitle: e.target.value})}/>
															):<span>{person.projectTitle}</span>
														}
													</div>

													<label>Project category</label>
													<div>
														{

															this.state.editMode ? (
																<select className="form-control" onChange={(e)=>this.props.onPersonUpdated({...person, categoryID: e.target.value})}>
																	<option/>
																	{
																		Object.keys(this.props.categories).map((categoryID) => {
																			let category = this.props.categories[categoryID];
																			return <option key={categoryID} value={categoryID} selected={categoryID==person.categoryID}>{category.name}</option>;
																		})
																	}
																</select>
															):<span>{person.categoryID in this.props.categories ? this.props.categories[person.categoryID].name : 'Invalid category'}</span>
														}
													</div>

													<label>Email</label>
													<div>
														{
															this.state.editMode ? (
																<input className="form-control" type="email" defaultValue={person.email} onBlur={(e)=>this.props.onPersonUpdated({...person, email: e.target.value})}/>
															):<a href={`mailto:${person.email}`}>{person.email}</a>
														}
													</div>

													<label>URL</label>
													<div>
														{
															this.state.editMode ? (
																<input className="form-control" type="url" defaultValue={person.url} onBlur={(e)=>this.props.onPersonUpdated({...person, url: e.target.value})}/>
															):(person.url ? <a target="_blank" href={person.url}>Open in new tab</a> : null)
														}
													</div>

													<label>Details</label>
													<div>
														{
															this.state.editMode ? (
																<textarea className="form-control" defaultValue={person.details} onBlur={(e)=>this.props.onPersonUpdated({...person, details: e.target.value})}/>
															):<span>{person.details}</span>
														}
													</div>

													<label>Judge feedback</label>

													<details>
														<summary>Click to see options</summary>

														<div className="responsive-form table tight">
															<label>Option</label>
															<label>Setting</label>

															<label>Include Z-scores</label>
															<label><input type="checkbox" onChange={(e)=>this.setState({'sendZScores':e.target.checked})} checked={this.state.sendZScores} /></label>

															<label>Include comments</label>
															<label><input type="checkbox" onChange={(e)=>this.setState({'sendComments':e.target.checked})} checked={this.state.sendComments} /></label>
														</div>

														<button className={'btn btn-primary' + (this.state.emailSending?' disabled':'')} onClick={()=>this.sendScoreSheets()} disabled={!(this.state.sendComments||this.state.sendZScores)}>
															{
																!(this.state.sendComments||this.state.sendZScores) ? (
																	<span>No options selected</span>
																):(
																	<span>Send
																		{this.state.sendZScores ? ' Z-scores ':''}
																		{this.state.sendZScores && this.state.sendComments ? 'and':''}
																		{this.state.sendComments ? ' comments ':''}
																		to {person.name} now
																	</span>
																)
															}
														</button>

													</details>

													{
														this.state.editMode ? (
															<React.Fragment>
																<label>&nbsp;</label>
																<div>
																	<button className="btn btn-sm btn-danger" onClick={()=>this.props.onPersonUpdated({...person, deleted:!person.deleted})}>
																		{person.deleted ? 'Restore':'Delete'} {person.name}
																	</button>
																</div>
															</React.Fragment>
														):null
													}
												</div>
											</React.Fragment>
										):null
									}

								</React.Fragment>
						})
					}
				</div>
			</div>
		);
	}

	sendScoreSheets(){
		if(!window.confirm(`This will send an email to "${this.state.expandedPerson.name}" <${this.state.expandedPerson.email}> - are you sure?`)){
			return;
		}
		this.setState({emailSending: true});
		Model.sendParticipantScoreSheets(this.props.groupID, this.props.eventID, this.state.expandedPerson.id, this.state.sendZScores, this.state.sendComments)
			.then((result)=>{
				if(result){
					this.props.onError({
						code: null,
						message: result,
					}, 'Error sending feedback to participant');
				}else{
					this.props.onMessage('Email has been queued');
				}

				this.setState({emailSending: false})
			}).catch((exc) => {
				this.setState({emailSending: false});
				this.props.onError(exc, 'Error sending feedback to participant');
			});
	}
}
