import React, { Component } from 'react';

export default class CopyToClipboardButton extends Component {
	constructor(){
		super();
		this.state = {
			copyStatus: null,
		};
		this.resetTimer = null;
	}

	temporarilySetStatus(status){
		clearTimeout(this.resetTimer);

		this.setState({'copyStatus': status});
		this.resetTimer = setTimeout(
			()=>{this.setState({'copyStatus': null})},
			2000
		);
	}

	copyToClipboard(){
		navigator.clipboard.writeText(this.props.copyText)
			.then( () => {
				this.temporarilySetStatus(true);
			}, () => {
				this.temporarilySetStatus(false);
			});
	}

	render(){
		return <button
			className={'btn btn-secondary' + (this.props.className ? (' '+this.props.className) : '')}
			onClick={()=>this.copyToClipboard()}
		>
			{this.state.copyStatus === true ? this.props.contentOnCopied||'Copied!' : ''}
			{this.state.copyStatus === false ? this.props.contentOnFailed||'Failed!' : ''}
			{this.state.copyStatus === null ? this.props.children||'🗊 Copy' : ''}
		</button>
	}
}
