import React, { Component } from 'react';

export default class SectionCard extends Component {
	render() {
		return (
			<div className={'section-card' + (this.props.className ? (' '+this.props.className) : '')}>
				{
					(this.props.buttons || this.props.title) ? (
						<div className="card-header">
							<div className="button-container">{this.props.buttons || null}</div>
							<h4>{this.props.title}</h4>
						</div>
					):null
				}
				<div className="card-body">
					{this.props.children}
				</div>
			</div>
		);
	}
}
