import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

import LoadingScreen from './loadingScreen';
import SectionCard from '../components/sectionCard';
import EmbeddedVideo from '../components/embeddedVideo';

import Model from '../model';

import '../css/event-landing.css';

export default class EventLandingScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: true,
			frameURL: null,
		};
	}

	componentDidMount(){
		this.setState({loading:true});
		this.groupID = this.props.match.params.groupID;
		this.eventID = this.props.match.params.eventID;

		let promises = [
			Model.getGroupInfo(this.groupID).then(group => {
				this.setState({group: group});
				return group;
			}),
			Model.getEvent(this.groupID, this.eventID, false, true, false)
		];

		Promise.all(promises).then(([group, event]) => {
			let participants = [];
			for(let participantID in event.participants){
				let participant = event.participants[participantID];
				participant.id = participantID;
				participants.push(participant);
			}

			this.participants = participants;

			console.log('event', event);

			this.setState({
				loading: false,
				...event,
				searchFilter: '',
			});
		}).catch((exc) => {
			this.props.onError(exc);
			this.setState({loading: false});
		});
	}

	applyFilter(search){
		this.setState({searchFilter: search.toLowerCase()});
	}

	render() {
		if(this.state.loading){
			return <LoadingScreen />;
		}

		let participants = this.participants;
		if(this.state.searchFilter){
			participants = participants.filter(participant => {
				let nameMatch = participant.name.toLowerCase().indexOf(this.state.searchFilter) > -1;
				let projectMatch = participant.projectTitle && participant.projectTitle.toLowerCase().indexOf(this.state.searchFilter) > -1;

				return nameMatch || projectMatch;
			});
		}

		if(!this.state.group){
			return '';
		}

		return (
			<div className="page event-landing-screen">
				<h2>{this.state.group.name}</h2>
				<h3>{this.state.name||''}</h3>

				{
					participants ? (
						<div id="content-container">
							<div id="list">
								<SectionCard title="Participants">
									<p><input className="form-input" placeholder="🔍 Filter..." onChange={(e)=>this.applyFilter(e.target.value)}/></p>
									<table className="">
										<thead>
											<tr><th>Name</th><th>Project</th></tr>
										</thead>
										<tbody>
											{
												participants.map((participant) => {
													return <tr key={participant.id} className={this.state.frameParticipant === participant ? 'selected': ''}>
														<td>{participant.name}</td>
														<td>
															<a
																href={participant.url ? participant.url : null}
																onClick={(e)=>this.loadParticipant(participant, e)}
															>{participant.projectTitle}</a>
														</td>
													</tr>
												})
											}
										</tbody>
									</table>

									{
										participants.length === 0 ? (
											<p style={{textAlign: 'center'}}>No results :(</p>
										):null
									}
								</SectionCard>
							</div>
							<div className="viewer-container">
							<div id="viewer">
								{
									this.state.frameParticipant ? (
											<SectionCard title={this.state.frameParticipant.projectTitle}>
												<div>{this.state.frameParticipant.name}</div>
												<EmbeddedVideo style={{textAlign: 'center'}} src={this.state.frameParticipant.url} />
												<div style={{textAlign: "justify"}}><ReactMarkdown>{this.state.frameParticipant.details}</ReactMarkdown></div>
											</SectionCard>
									) : null
								}
							</div>
							</div>

						</div>
					):(
						<p>Unable to load data :(</p>
					)
				}
			</div>
		)
	}

	loadParticipant(participant, event){
		if(event){
			event.preventDefault();
		}

		this.setState({frameParticipant: participant});
		document.getElementById('viewer').scrollIntoView();

		return false;
	}
}
