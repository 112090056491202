import React, { Component } from 'react';

import SectionCard from '../components/sectionCard';

import Model from '../model';

export default class LoginScreen extends Component {
	constructor(){
		super();
		this.state = {
			loading: false,
			password: null,
			passwordConfirm: null,
			creating: false,
		};
	}

	componentDidMount(){
		Model.auth.onAuthStateChanged((user) => {
			if(user){
				this.props.history.push('/me');
			}
		});
	}

	render() {
		//<div><button onClick={()=>this.loginWithGoogle()}>Login with Google</button></div>
		return (
			<div className="page">
				<SectionCard title="Login" className="squeeze">
					<form className="form-horizontal" onSubmit={(e)=>{ this.formSubmit(); e.preventDefault(); return false; }}>
						<div className="form-group row">
							<label className="control-label col-form-label col-sm-2">
								Email
								<input className="form-control" type="email" placeholder="email@example.com" onChange={(e)=>this.setState({email:e.target.value})}/><br/>
							</label>
						</div>

						<div className="form-group row">
							<label className="control-label col-form-label col-sm-2">Password
								<input className="form-control" type="password" placeholder="Password" onChange={(e)=>this.setState({password:e.target.value})}/><br/>
							</label>
						</div>

						{
							this.state.creating ? (
								<div className="form-group row ">
									<label className="control-label col-form-label col-sm-2">Password</label>
									<div className="col-sm-10">
										<input className="form-control" type="password" placeholder="Confirm password" onChange={(e)=>this.setState({passwordConfirm:e.target.value})}/><br/>
									</div>
								</div>
							):null
						}
						<div>
							<input type="submit" className="btn btn-primary" value="Login" />
							<button className="btn btn-primary" value="Login">Login</button>
						</div>
					</form>
				</SectionCard>
			</div>
		);
	}

	formSubmit(){
		this.login();
	}

	loginWithGoogle(){
		console.log('Login with google...');
		var provider = new Model.auth.GoogleAuthProvider();
		Model.auth.signInWithPopup(provider).then((result)=>{
			console.log(result.user);
		}).catch((error)=>{
			this.handleError(error, 'Oh no! We couldn\'t log you in');
		});
	}

	login(){
		var email = this.state.email;
		var password = this.state.password;
	
		Model.auth.signInWithEmailAndPassword(email, password).catch((error) => {
			this.handleError(error, 'Login failed');
		}).then(() => {});
	}
}
